import { OverviewButtonsProps, OverviewInfoProps, ReportOverviewInfo } from './components';

type OverviewProps = OverviewButtonsProps & OverviewInfoProps & {}

export const ReportOverview = (props: OverviewProps) => {

  return (
    <ReportOverviewInfo {...props} />
  );
};
