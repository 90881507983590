import { useSelector } from 'react-redux';
import { CardReportItem, OverviewButtons } from 'src/features/report-detail';
import { useGetBorrowerItems, useGetBusinessItems, useGetDebtChainedItems, useGetHoldingItems, useGetQuotationChainedItems } from 'src/hooks';
import { RootState } from 'src/store';
import { fDate } from 'src/utils';

import { DebtDetailsType, ReportKeyType, ReportSettingsType } from '../../../../../../../types';

export type FinRequestInfoAboutProps = {
  type: ReportKeyType;
  detailsList?: DebtDetailsType;
  openDetailedProjectClickHandler?: VoidFunction;
  openDocumentsClickHandler?: VoidFunction;
  isSettingExist?: ReportSettingsType;
}

export const FinRequestInfoAbout = (props: FinRequestInfoAboutProps) => {
  const {
    type,
    detailsList,
    openDocumentsClickHandler,
    openDetailedProjectClickHandler,
    isSettingExist,
  } = props;

  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);

  const firstPreliminaryRequest = detailsData.debt;
  const firstPreliminaryQuotation = detailsData.quotation;
  const scenario = detailsData.project?.scenario;
  const isRequestActive = isSettingExist?.sections?.find(section => section.section === 'Our Request');

  const isBank = type === 'bank';
  const isSale = type === 'seller';
  const isSupplier = type === 'supplier';
  const showOverviewButtons = isSale;
  const quotationChainedItems = useGetQuotationChainedItems(firstPreliminaryQuotation, scenario);
  const debtChainedItems = useGetDebtChainedItems(scenario, firstPreliminaryRequest, detailsList);
  const holdingItems = useGetHoldingItems();
  const borrowedItems = useGetBorrowerItems();
  const businessItems = useGetBusinessItems();

  return (
    <>
      {(isBank && isRequestActive?.isActive) && (
        <CardReportItem
          title='Preliminary Debt Requests'
          suTitle={firstPreliminaryRequest?.note}
          chainedItems={debtChainedItems}
          date={fDate(firstPreliminaryRequest?.updatedAt)}
        />
      )}
      {(isSupplier && isRequestActive?.isActive) && (
        <CardReportItem
          title='Quotation Request'
          suTitle='Det färdigställda projektet, som uppförs i totalt två etapper om 27 bostäder,
            har ett beräknat
            intäktsvärde om ca 59,6 mkr med en belåningsgrad om ca 60% (LTGDV).'
          chainedItems={quotationChainedItems}
        />
      )}
      <CardReportItem
        title='Holding Company'
        oppositeItems={holdingItems}
      />

      {isBank &&
        <CardReportItem
          title='Proposed Borrower'
          oppositeItems={borrowedItems}
        />
      }

      <CardReportItem
        title='Summarized business description'
        oppositeItems={businessItems}
      />

      {showOverviewButtons &&
        <OverviewButtons
          openDocumentsClickHandler={openDocumentsClickHandler}
          openDetailedProjectClickHandler={openDetailedProjectClickHandler}
        />
      }
    </>
  );
};
