import { AttachFile } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import React, { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { FileItem, UploadedFilesType } from 'src/components';
import { Editor } from 'src/components/editor';
import { AddDocuments } from 'src/features/project-preparation-details/components/assets/project-documents/add-documents/add-documents';
import { useBoolean, useDocuments, usePortalFlags } from 'src/hooks';
import { RootState } from 'src/store';
import { openInNewTab } from 'src/utils';

import { FileForUpload, FinanceUploadFileType, NoteSettingType } from '../../../../../types';

type CheckboxesProps = {
  isNotesSection?: boolean;
  isPreview?: boolean;
  selected?: NoteSettingType[];
  documentsModalList?: UploadedFilesType[];
  setSelected?: Dispatch<SetStateAction<NoteSettingType[]>>;
  setDocumentsModalList?: Dispatch<SetStateAction<UploadedFilesType[]>>;
};

export const Checkboxes = (props: CheckboxesProps) => {
  const {
    isNotesSection = false,
    isPreview,
    setSelected,
    setDocumentsModalList,
    selected = [],
    documentsModalList = [],
  } = props;

  const { isCalculatorReport: isCalculatorReportFlag } = usePortalFlags();
  const isCalculatorReport = isCalculatorReportFlag || isPreview;

  const drawerBool = useBoolean();
  const [activeItem, setActiveItem] = useState<NoteSettingType | null>(null);
  const { createAndUploadDocument } = useDocuments();
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const partnerId = useSelector((state: RootState) => state.Auth.user?.partnerId || '');

  const updatedList: NoteSettingType[] = (!isCalculatorReport)
    ? selected
    : selected.filter(item => item.section !== 'Our Request');

  const toggle = (value: NoteSettingType) => () => {
    const toggledList = updatedList.map(item => {
      return item.section === value.section ? {
        ...item,
        isActive: !item.isActive,
      } : item;
    });
    setSelected?.(toggledList);
  };

  const setNote = (value: NoteSettingType) => (newNote: string) => {
    const toggledList = updatedList.map(item => {
      return item.section === value.section ? {
        ...item,
        note: newNote,
      } : item;
    });
    setSelected?.(toggledList);
  };

  const onAttachClickHandler = (item: NoteSettingType) => () => {
    setActiveItem(item);
    drawerBool.onTrue();
  };

  const onAddDocuments = (docs: UploadedFilesType[]) => {
    drawerBool.onFalse();
    const listWithFiles: NoteSettingType[] = updatedList.map(item => {
      return item.section === activeItem?.section ? {
        ...item,
        files: docs,
      } : item;
    });
    setSelected?.(listWithFiles);
    setActiveItem(null);
  };

  const modalUploadFileHandler = async (file: FinanceUploadFileType) => {
    const uploadedFile = await createAndUploadDocument(file, partnerId, detailsData.project?._id, detailsData.lead?._id);
    if (uploadedFile) {
      setDocumentsModalList?.([...documentsModalList, {
        ...uploadedFile,
        size: Math.round((uploadedFile.size || 0) / 1024),
        filename: uploadedFile.sectionFilename || uploadedFile.filename,
      }]);
    }
  };

  const onFileClickHandler = (file: FileForUpload) => () => {
    openInNewTab(file.url);
  };

  const onDeleteFileClickHandler = (activeItem: NoteSettingType) => (fileToRemove: FileForUpload) => (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const clearedList: NoteSettingType[] = updatedList.map(item => {
      return item.section === activeItem?.section ? {
        ...item,
        files: item.files?.filter(file => file._id !== fileToRemove._id),
      } : item;
    });
    setSelected?.(clearedList);
  };

  const onCloseDrawerHandler = () => {
    drawerBool.onFalse();
    setActiveItem(null);
  };

  return (
    <Stack gap={1}>
      {updatedList.map((checkbox, i) => (
        <Stack key={i} gap={2}>
          <FormControlLabel
            control={
              <Switch
                checked={checkbox.isActive}
                onChange={toggle(checkbox)}
              />
            }
            label={checkbox.section}
          />
          {(isNotesSection && checkbox.isActive) &&
            <>
              <Stack>
                <Typography variant='body2' fontWeight={600} mb={0.5}>
                  Basic Details
                </Typography>
                <Editor
                  id={`minimal-quill-notes-${i}`}
                  value={checkbox.note}
                  onChange={setNote(checkbox)}
                  placeholder='Description'
                  headings={[]}
                  simple
                />
              </Stack>
              <Button
                variant='outlined'
                startIcon={<AttachFile />}
                onClick={onAttachClickHandler(checkbox)}
              >
                Attach Document
              </Button>
              <Stack>
                {checkbox.files?.map(file => {
                  return (
                    <FileItem
                      key={file.url}
                      file={file}
                      icon='mingcute:close-line'
                      onFileClickHandler={onFileClickHandler}
                      onIconClickHandler={onDeleteFileClickHandler(checkbox)}
                    />
                  );
                })}
              </Stack>
            </>
          }
          {i !== updatedList.length - 1 && <Divider sx={{ borderStyle: 'dashed' }} />}
        </Stack>
      ))}

      <AddDocuments
        isAttachButton
        onCloseDrawerHandler={onCloseDrawerHandler}
        btnSx={{
          ml: 'auto',
          mr: 0,
        }}
        drawer={drawerBool}
        documentsList={documentsModalList}
        modalUploadFileHandler={modalUploadFileHandler}
        alreadySelectedDocs={activeItem?.files}
        onAdd={onAddDocuments}
      />
    </Stack>
  );
};
