import { QuotationContractColors, QuotationStageColors } from '../../../../../../types';

export const quotationStageColor: QuotationStageColors = {
  'Quotation': 'info',
  'Detailed': 'primary',
  'Itemized': 'secondary',
  'Detailed requested': 'error',
  'Itemized requested': 'warning',
};

export const quotationContractColor: QuotationContractColors = {
  'Term Sheet': 'success',
  'Term sheet requested': 'secondary',
};
