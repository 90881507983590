import { AppBar, Button, Stack, Toolbar, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { EditIcon, ShareIcon } from 'src/assets';
import { Logo } from 'src/components';
import { INVESTOR_PORTAL } from 'src/constants/constants';
import { useResponsive } from 'src/hooks';
import { bgBlur } from 'src/theme/css';
import { copyToClipboard } from 'src/utils';

type HeaderProps = {
  onEditClick: () => void;
  isEditAvailable?: boolean;
  isPreview?: boolean;
}

export const Header = (props: HeaderProps) => {
  const {
    onEditClick,
    isEditAvailable,
    isPreview,
  } = props;

  const theme = useTheme();
  const lgUp = useResponsive('up', 'lg');
  const mdUp = useResponsive('up', 'md');
  const { enqueueSnackbar } = useSnackbar();

  const onShareClick = () => {
    copyToClipboard(window.location.href);
    enqueueSnackbar('Link copied!', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
  };

  const renderContent = (
    <>
      <Logo sx={{ mr: 2.5 }} />

      {mdUp &&
        <Stack
          flexGrow={1}
          direction='row'
          alignItems='center'
          justifyContent='flex-end'
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <Button
            onClick={onShareClick}
            variant='outlined'
            size='small'
            startIcon={<ShareIcon />}
          >
            Share
          </Button>
          <Button
            onClick={onEditClick}
            variant='outlined'
            size='small'
            startIcon={<EditIcon />}
            disabled={!isEditAvailable}
          >
            Edit
          </Button>
        </Stack>
      }
    </>
  );

  return (
    <AppBar
      sx={{
        height: INVESTOR_PORTAL.HEADER_H,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({ color: theme.palette.background.default }),
        transition: theme.transitions.create(['height'], { duration: theme.transitions.duration.shorter }),
        boxShadow: theme.customShadows.z8,
        backgroundColor: isPreview ? theme.palette.background.default : 'none',
        ...(lgUp && {
          left: 0,
          right: 0,
          width: '100%',
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
};
