import { LeadBodyParams, ResearchResponse } from '../../../../types';
import { apiFetch } from '../api';
import { setIsLoading } from '../storage';
import { AppThunk } from '../store';
import { deleteResearch, setResearches } from './index';

export const loadResearch = (paramBody: LeadBodyParams): AppThunk => async (dispatch, getState) => {
  dispatch(setIsLoading(true));
  const partnerId = getState().Auth.user?.partnerId || '';

  try {
    const researches: ResearchResponse = await apiFetch('research/list', {
      method: 'post',
      body: JSON.stringify(paramBody),
      headers: { 'Content-Type': 'application/json', partnerId },
    });

    if (researches !== undefined) {
      dispatch(setResearches(researches));
    }
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};

export const deleteResearchThunk = (researchId: string): AppThunk => async (dispatch) => {
  dispatch(deleteResearch(researchId));
};
