import { omit, pick } from 'lodash';
import { CreateNewUserType, UpdateUserType } from 'src/features/users-page/types';

import { AddFileSectionResponseType, CompanyInviteType, Config, FileSectionsType, FinanceUploadFileType, ShortUser, User } from '../../../../types';
import { paths } from '../../config';
import { apiFetch } from '../api';
import { authInitAction } from '../auth';
import { AppThunk } from '../store';
import {
  addFileSection,
  deleteUser,
  setConfig,
  setConfigError,
  setConfigStatus,
  setFileSections,
  setShortUserList,
  setUser,
  setUserList,
  setUserListStatus,
  setUserStatus,
} from '.';

export const loadConfig = (callBack?: () => void): AppThunk => async (dispatch) => {
  try {
    dispatch(setConfigStatus('load'));
    const config = await apiFetch('config', {
      method: 'get',
      returnError: true,
    }) as Config | {
      error: string
    };

    if ('error' in config) {
      dispatch(setConfigStatus('error'));
      dispatch(setConfigError(config.error));
    } else {
      dispatch(setConfig(config));
      dispatch(authInitAction());
      callBack?.();
      dispatch(setConfigStatus('success'));
    }

  } catch (error) {
    console.error(error);
    dispatch(setConfigStatus('error'));
    dispatch(setConfigError((error as Error).message));
  }
};

export const getUser = (userId?: string): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setUserStatus('load'));

    const user = await apiFetch(`user${userId ? `/${userId}` : ''}`);

    if (!user) {
      console.error('Can not update user');
      return;
    }

    dispatch(setUser(user));
    dispatch(setUserStatus('success'));
  } catch (error) {
    console.error(error);
    dispatch(setUser(null));
    dispatch(setUserStatus('error'));
  }
};

export const getUserList = (short?: boolean): AppThunk => async (dispatch, getState) => {
  const partnerId = getState().Auth.user?.partnerId || '';

  try {
    dispatch(setUserListStatus('load'));

    const userList = await apiFetch(`user/list${short ? '/short' : ''}`, { headers: { partnerId } }) as User[] | ShortUser[];
    if (!userList) {
      console.error('Can not update user list');
      return;
    }

    if (short) {
      dispatch(setShortUserList((userList as ShortUser[]).sort((a, b) => a.displayName.toUpperCase() < b.displayName.toUpperCase() ? -1 : 1)));
    } else {
      dispatch(setUserList((userList as User[])));
    }
    dispatch(setUserListStatus('success'));
  } catch (error) {
    console.error(error);
    dispatch(setUserList([]));
    dispatch(setUserListStatus('error'));
  }
};

export type GetCompanyInvitesType = {
  isUser?: boolean
  isPartner?: boolean
  onlyPending?: boolean
  email?: string
}

export const getCompanyInvites = async (params: GetCompanyInvitesType) => {
  try {
    const {
      isUser = false,
      isPartner = false,
      onlyPending = false,
      email,
    } = params;
    const invites = await apiFetch('user/invites', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        isPartner,
        isUser,
        onlyPending,
      }),
    });
    return invites;
  } catch (error) {
    console.error(error);
  }
};

export const acceptCompanyInvites = (companyInvites: CompanyInviteType[] = []): AppThunk => async () => {
  try {
    await Promise.all(companyInvites.map((invite) => apiFetch(`user/accept-invite/${invite}`)));
    window.location.href = paths.dashboard;
  } catch (error) {
    console.error(error);
  }
};

export const declineCompanyInvite = async (email: string) => {
  try {
    await apiFetch('user/decline-invite', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });
  } catch (error) {
    console.error(error);
  }
};
export const inviteUserToCompany = async (email: string) => {
  try {
    await apiFetch('user/invite', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateUserProfileThunk = (user: User): AppThunk => async (dispatch) => {
  try {
    const pickedUser = pick(user, ['userFirstAction', 'name', 'surname',
      'phoneNumber', 'agreedTerms', 'agreedSubscription', 'jobTitle',
    ]);
    await apiFetch('auth/profile', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(pickedUser),
    });
    dispatch(setUser(user));
  } catch (error) {
    console.error(error);
  }
};

export const addUserToPartner = (partnerId: string): AppThunk => async (dispatch, getState) => {
  try {
    const user = getState().Auth.user;
    await apiFetch(`user/add-to-partner/${partnerId}`);

    if (user) {
      dispatch(setUser({
        ...user,
        partnerId,
      }));
    }
    window.location.href = paths.dashboard;
  } catch (error) {
    console.error(error);
  }
};

export const sendWelcomeEmail = (name: string, email: string, partnerId: string): AppThunk => async () => {
  try {
    await apiFetch('user/send-welcome-email', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
      body: JSON.stringify({
        name,
        email,
      }),
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteUserThunk = (userId: string): AppThunk => async (dispatch, getState) => {
  try {
    const partnerId = getState().Auth.user?.partnerId || '';

    await apiFetch(`user/delete/${userId}`, {
      method: 'delete',
      headers: { partnerId },
    });
    dispatch(deleteUser(userId));
  } catch (error) {
    console.error(error);
  }
};

export const createNewUser = (newUser: CreateNewUserType): AppThunk => async () => {
  try {
    const createdUser = await apiFetch('user/create', {
      method: 'post',
      body: JSON.stringify(omit(newUser, ['role', 'avatar'])),
    });

    if (newUser.avatar && createdUser) {
      await apiFetch('userpic', {
        method: 'post',
        body: newUser.avatar,
        headers: { 'Content-Type': newUser.avatar.type },
      });
    }
  } catch (error) {
    console.error(error);
  }
};
export const updateUserThunk = (updatedUser: UpdateUserType): AppThunk => async (dispatch, getState) => {
  try {
    // const createdUser = await apiFetch('user/create', {
    //   method: 'post',
    //   body: JSON.stringify(omit(newUser, ['role', 'avatar'])),
    // });

    // if (updatedUser.avatar && createdUser) {
    //   const uploadedAvatar = await apiFetch('userpic', {
    //     method: 'post',
    //     body: updatedUser.avatar,
    //     headers: { 'Content-Type': updatedUser.avatar.type },
    //   });
    // }
  } catch (error) {
    console.error(error);
  }
};

export const getFileSections = (leadId: string = '', projectId: string = ''): AppThunk => async (dispatch, getState) => {
  const partnerId = getState().Auth.user?.partnerId || '';

  try {
    const fileSections: FileSectionsType[] = await apiFetch(`file-section/list/${leadId}/${projectId}`, {
      method: 'get',
      headers: { partnerId },
    });
    dispatch(setFileSections(fileSections || []));
  } catch (error) {
    console.error(error);
    dispatch(setFileSections([]));
  }
};

export const addFileSectionThunk = (paramBody: FinanceUploadFileType): AppThunk => async (dispatch, getState) => {
  try {
    const partnerId = getState().Auth.user?.partnerId || '';

    const correspondingBody = {
      section: paramBody.section,
      filename: paramBody.filename,
    };
    const response: AddFileSectionResponseType = await apiFetch('file-section/', {
      method: 'post',
      body: JSON.stringify(correspondingBody),
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
    });

    if (!response) {
      console.error('Can not create new file section');
      return;
    }

    const newSection: FileSectionsType = {
      section: paramBody.section || 'Lead',
      filenames: [{
        ...paramBody,
        files: [],
        _id: response.fileSectionId,
      }],
    };
    dispatch(addFileSection(newSection));
  } catch (error) {
    console.error(error);
    dispatch(setFileSections([]));
  }
};
