import { convertUnitsArrayToString } from 'src/components';
import { TenderingBodyParams } from 'src/features/tendering-page';

import { ConstructionCostArray, DocumentListResponse, QuotationType, TenderingResponse } from '../../../../types';
import { apiFetch } from '../api';
import { setIsLoading } from '../storage';
import { AppThunk } from '../store';
import { deleteTendering, setTenderingList } from './index';
import { CreateTenderingRequestBodyType, TenderingRequestDetails } from './types';

export const loadTenderingList = (paramBody: TenderingBodyParams): AppThunk => async (dispatch, getState) => {
  dispatch(setIsLoading(true));
  const partnerId = getState().Auth.user?.partnerId || '';

  try {
    const tenderingList: TenderingResponse = await apiFetch('tendering/list', {
      method: 'post',
      body: JSON.stringify(paramBody),
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
    });
    if (tenderingList !== undefined) {
      dispatch(setTenderingList(tenderingList));
    }
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};

export const deleteTenderingThunk = (id: string): AppThunk => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    dispatch(deleteTendering(id));
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};

export const getTenderingRequestDetails = async (projectId: string, partnerId: string): Promise<TenderingRequestDetails | undefined> => {
  try {
    const response = await apiFetch(`tendering/request-details/${projectId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
    });
    return {
      ...response,
      constructionCostAmount: response.constructionCost?.reduce((acc: number, el: ConstructionCostArray) => {
        return acc + (el.total_cost || 0);
      }, 0),
      unitTypeText: convertUnitsArrayToString(response.unitType),
    };
  } catch (error) {
    console.error(error);
  }
};

export const createTenderingRequest = (params: CreateTenderingRequestBodyType, callBack?: VoidFunction): AppThunk => async (_, getState) => {
  const partnerId = getState().Auth.user?.partnerId || '';

  const {
    scenarioId,
    projectId,
    ...rest
  } = params;

  try {
    await apiFetch(`tendering/create-request/${projectId}/${scenarioId}`, {
      method: 'post',
      body: JSON.stringify(rest),
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
    });
    callBack?.();

  } catch (error) {
    console.error(error);
  }
};


export const updateQuotation = (quotationId: string, dataToUpdate: Partial<QuotationType>, callBack?: VoidFunction): AppThunk => async (_, getState) => {
  const partnerId = getState().Auth.user?.partnerId || '';

  try {
    await apiFetch(`tendering/quotation-update/${quotationId}`, {
      body: JSON.stringify(dataToUpdate),
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
      method: 'post',
    });
    callBack?.();
  } catch (error) {
    console.error(error);
  }
};


export const deleteQuotation = (quotationId: string): AppThunk => async (_, getState) => {
  const partnerId = getState().Auth.user?.partnerId || '';

  try {
    await apiFetch(`tendering/quotation-delete/${quotationId}`, {
      method: 'delete',
      headers: { partnerId },
    });
  } catch (error) {
    console.error(error);
  }
};

export const getQuotationUploadedFiles = async (quotationId: string, partnerId: string) => {
  try {
    const response: DocumentListResponse[] = await apiFetch(`tendering/quotation-files/${quotationId}`, {
      method: 'get',
      headers: { partnerId },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};
