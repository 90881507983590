import Stack from '@mui/material/Stack';
import { OverviewButtons, OverviewSidebar } from 'src/features/report-detail';
import { TenderingRequestDetails } from 'src/store/tendering/types';

import { AssigneeType, DebtDetailsType, ReportKeyType, ReportSettingsType } from '../../../../../../../types';
import { FinRequestInfoAbout } from './fin-request-info-about';

type FinRequestInfoProps = {
  type: ReportKeyType;
  requestDetails?: TenderingRequestDetails;
  isSettingExist?: ReportSettingsType;
  detailsList?: DebtDetailsType;
  projectManager?: AssigneeType;
}

export const FinRequestInfo = (props: FinRequestInfoProps) => {
  const {
    type,
    projectManager,
  } = props;
  const isBank = type === 'bank';

  return (
    <Stack direction='row' gap={5} flexWrap='wrap-reverse'>
      <Stack gap={3} sx={{ flex: 1 }}>
        <FinRequestInfoAbout
          {...props}
        />
        {isBank && <OverviewButtons/>}
      </Stack>
      <OverviewSidebar type={type} projectManager={projectManager}/>
    </Stack>
  );
};
