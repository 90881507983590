import { TableHeadType } from '../leads-page';
import { TableFilters } from './types';

export const TABLE_HEAD: TableHeadType[] = [
  {
    _id: '',
    label: 'Name / Type',
    width: 110,
  },
  {
    _id: '',
    label: 'GDV / Project size',
    width: 110,
    align: 'right',
  },
  {
    _id: '',
    label: 'Build Costs / psqm',
    width: 130,
    align: 'right',
  },
  {
    _id: 'lastActivity',
    label: 'Last Activity',
    width: 210,
  },
  {
    _id: '',
    label: 'Status',
    width: 110,
  },
  {
    _id: 'controls',
    width: 10,
  },
];

export const defaultFilters: TableFilters = {
  text: '',
  projectType: [],
  status: 'New',
};


export const allFilters: TableFilters = {
  ...defaultFilters,
  status: 'All',
};

export const QUOTATION_REQUESTS_TABLE_HEAD: TableHeadType[] = [
  {
    _id: '',
    label: 'Supplier',
    width: 250,
  },
  {
    _id: '',
    label: 'Services / Fee',
    width: 160,
  },
  {
    _id: '',
    label: 'Contact Person',
    width: 160,
  },
  {
    _id: '',
    label: 'Email / Phone',
    width: 150,
  },
  {
    _id: '',
    width: 145,
  },
];

export const QUOTATIONS_TABLE_HEAD: TableHeadType[] = [
  {
    _id: '',
    label: 'Supplier',
    width: 200,
  },
  {
    _id: '',
    label: 'Build Costs / psqm',
    width: 140,
    align: 'right',
  },
  {
    _id: '',
    label: 'Build Time',
    width: 102,
    align: 'right',
  },
  {
    _id: '',
    label: 'Services / Fee',
    width: 160,
  },
  {
    _id: '',
    label: 'Stage',
    width: 170,
  },
  {
    _id: '',
    label: 'Status',
    width: 170,
  },
  {
    _id: '',
    label: 'Calculation',
    width: 170,
  },
  {
    _id: '',
    width: 110,
  },
];
