import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { alpha, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CrumbType, DetailsList, FormProvider, generateSummaryField, ProjectToolbar, RHFTextField, UploadedFilesType } from 'src/components';
import { paths } from 'src/config';
import { ProjectDocuments } from 'src/features/project-preparation-details/components/assets/project-documents/project-documents';
import { _projectTypeList } from 'src/features/projects-offering-page';
import { requestTypeVariants } from 'src/features/tendering-details';
import { useBoolean, useDocuments, useRouter, useScrollToError } from 'src/hooks';
import { RootState } from 'src/store';
import { loadLeadDetails } from 'src/store/lead';
import { getPartners } from 'src/store/partners';
import { createTenderingRequest, getTenderingRequestDetails } from 'src/store/tendering';
import { convertToOnlyNumber, fNumber, getAllFiles } from 'src/utils';
import * as Yup from 'yup';

import { FinanceUploadFileType, PartnerItemType, ProjectType, UnitsArray } from '../../../../../../types';
import { EditDrawer } from './edit-drawer';
import { SuccessDrawer } from './success-drawer';
import { SuppliersModal } from './suppliers-modal';

export type PreliminaryQuotationRequestFormType = {
  uploadedDocuments?: UploadedFilesType[];
  suppliers: PartnerItemType[];
  requestType: string;
  partnerFee?: string;
  emailNote?: string;
  projectType?: ProjectType;
  location?: string;
  totalBoa?: string;
  projectId?: string;
  scenarioId?: string;
  buildTime?: number;
  constructionCostAmount?: string;
  unitType?: UnitsArray[];
  unitTypeText?: string;
}

export const PreliminaryQuotationRequest = () => {
  const [documentsModalList, setDocumentsModalList] = useState<UploadedFilesType[]>([]);

  const dispatch = useDispatch();
  const suppliersDrawer = useBoolean();
  const editDrawer = useBoolean();
  const router = useRouter();

  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const suppliersFromDB = useSelector((state: RootState) => state.Partners.suppliers);
  const fileSections = useSelector((state: RootState) => state.App.fileSections);
  const partnerId = useSelector((state: RootState) => state.Auth.user?.partnerId || '');

  const { createAndUploadDocument } = useDocuments();

  const QuotationSchema: Yup.ObjectSchema<PreliminaryQuotationRequestFormType> = Yup.object().shape({
    uploadedDocuments: Yup.array(),
    unitType: Yup.array(),
    suppliers: Yup.array().required('Suppliers are required'),
    requestType: Yup.string().required('Request Type is required'),
    partnerFee: Yup.string(),
    emailNote: Yup.string(),
    projectType: Yup.string().oneOf(_projectTypeList),
    location: Yup.string(),
    totalBoa: Yup.string(),
    projectId: Yup.string(),
    scenarioId: Yup.string(),
    buildTime: Yup.number(),
    constructionCostAmount: Yup.string(),
    unitTypeText: Yup.string(),
  });

  const defaultValues: PreliminaryQuotationRequestFormType = useMemo(() => ({
    uploadedDocuments: [],
    unitType: [],
    suppliers: [],
    requestType: '',
    partnerFee: '',
    emailNote: '',
    projectType: undefined,
    location: '',
    totalBoa: '',
    buildTime: 0,
    constructionCostAmount: '',
    unitTypeText: '',
    scenarioId: '',
    projectId: '',
  }), []);

  const methods = useForm<PreliminaryQuotationRequestFormType>({
    resolver: yupResolver(QuotationSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: {
      isSubmitting,
      errors,
    },
  } = methods;
  useScrollToError(errors);
  const values = watch();

  const isPartner = values.requestType === 'Development Partner';
  const isSendDisabled = !values.suppliers.length || !values.requestType || (isPartner && !values.partnerFee);

  const loadTenderingRequestDetails = useCallback(async () => {
    if (detailsData.project?._id) {
      const requestDetails = await getTenderingRequestDetails(detailsData.project?._id, partnerId);
      if (!requestDetails) return;
      const {
        unitTypeText,
        unitType,
        projectType,
        location,
        totalBoa,
        buildTime,
        scenarioId,
        projectId,
        constructionCostAmount,
      } = requestDetails;
      setValue('unitTypeText', unitTypeText);
      setValue('unitType', unitType);
      setValue('projectType', projectType);
      setValue('location', location);
      setValue('totalBoa', String(totalBoa || 0));
      setValue('buildTime', buildTime);
      setValue('projectId', projectId);
      setValue('scenarioId', scenarioId);
      setValue('constructionCostAmount', String(constructionCostAmount || 0));
    }
  }, [detailsData.project?._id, setValue, partnerId]);

  const loadDocuments = useCallback(async () => {
    if (detailsData.project?._id) {
      const files = getAllFiles(fileSections);
      setDocumentsModalList(files.filter(doc => doc.url));
    }
  }, [detailsData.project?._id, fileSections]);

  useEffect(() => {
    if (!isPartner && values.partnerFee) {
      setValue('partnerFee', '');
    }
  }, [isPartner, setValue, values.partnerFee]);

  useEffect(() => {
    dispatch(getPartners('Supplier', 'suppliers'));
    reset();
  }, [reset, dispatch]);

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  useEffect(() => {
    loadTenderingRequestDetails();
  }, [loadTenderingRequestDetails]);


  const addSuppliers = (arr: PartnerItemType[]) => {
    const selectedIds = values.suppliers.map(s => s._id);
    const filtered = arr.filter(s => !selectedIds.includes(s._id));
    setValue('suppliers', [...values.suppliers, ...filtered]);
  };

  const deleteSupplier = (id: string) => () => {
    setValue('suppliers', values.suppliers.filter(supplier => supplier._id !== id));
  };

  const successDrawer = useBoolean();
  const onSubmit = handleSubmit(async (data) => {
    const {
      projectId,
      scenarioId,
      partnerFee,
      uploadedDocuments = [],
      emailNote,
      totalBoa,
      suppliers,
      constructionCostAmount,
    } = data;

    if (projectId && scenarioId) {
      dispatch(createTenderingRequest({
        ...data,
        projectId,
        scenarioId,
        totalBoa: convertToOnlyNumber(totalBoa),
        constructionCostAmount: convertToOnlyNumber(constructionCostAmount),
        fee: convertToOnlyNumber(partnerFee),
        documents: uploadedDocuments.map(document => document._id),
        emailNote,
        partnerId: suppliers.map(supplier => supplier._id),
      }, () => {
        successDrawer.onTrue();
        dispatch(loadLeadDetails(detailsData.lead?._id || ''));
      }),
      );
    }
  });

  const crumbs: CrumbType[] = [
    {
      title: 'Packaging',
      href: paths.tendering.list,
    },
    {
      title: 'Tendering',
      href: paths.tendering.list,
    },
    {
      title: detailsData.lead?.location?.address,
      href: paths.tendering.details(detailsData.project?._id || '', 'overview'),
    },
    { title: 'Quotation Request' },
  ];

  const onRemoveUploadedFileClickHandler = async (file: UploadedFilesType) => {
    const filteredDocuments = values.uploadedDocuments?.filter(doc => doc._id !== file._id);
    setValue('uploadedDocuments', filteredDocuments);
  };

  const onAddDocumentsClickHandler = (files: UploadedFilesType[]) => {
    setValue('uploadedDocuments', [...(values.uploadedDocuments || []), ...files]);
  };

  const onCloseClickHandler = () => {
    successDrawer.onFalse();
    if (detailsData.lead?._id) {
      router.push(paths.tendering.details(detailsData.lead?._id, 'suppliers'));
    }
  };

  const modalUploadFileHandler = async (file: FinanceUploadFileType) => {
    const uploadedFile = await createAndUploadDocument(file, partnerId, detailsData.project?._id, detailsData.lead?._id);
    if (uploadedFile) {
      setDocumentsModalList([...documentsModalList, {
        ...uploadedFile,
        size: Math.round((uploadedFile.size || 0) / 1024),
        filename: uploadedFile.sectionFilename || uploadedFile.filename,
      }]);
    }
  };

  const editClickHandler = useCallback(() => {
    editDrawer.onTrue();
  }, [editDrawer]);

  const fields = useMemo(() => {
    return generateSummaryField({
      projectType: values.projectType,
      location: values.location,
      totalBOA: fNumber(values.totalBoa),
      buildTime: values.buildTime,
      constructionCost: fNumber(values.constructionCostAmount),
      unitsType: values.unitTypeText,
    });
  }, [values.buildTime, values.constructionCostAmount, values.location, values.projectType, values.totalBoa, values.unitTypeText]);

  return (
    <>
      <ProjectToolbar
        showSkeleton={false}
        showBadge={false}
        title='Quotation Request'
        crumbs={crumbs}
      />
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack gap={3}>
          <Stack gap={3} direction='row'>
            <Typography sx={{ minWidth: 344 }} variant='h6'>Suppliers</Typography>
            <Card sx={{ flex: 1 }}>
              <CardContent>
                <Stack gap={3}>
                  <RHFTextField
                    name='suppliers'
                    onClick={suppliersDrawer.onTrue}
                    value={null}
                    label={
                      values.suppliers.length > 0 ? 'Suppliers *' : 'Click to select suppliers *'
                    }
                    InputProps={{
                      readOnly: true,
                      startAdornment:
                        <InputAdornment
                          sx={{
                            position: 'absolute',
                            display: 'flex',
                            overflow: 'hidden',
                            width: '96%',
                            height: '100%',
                          }}
                          position='start'
                        >
                          {values.suppliers?.map(supplier => (
                            <Chip
                              key={supplier._id}
                              label={supplier.companyName}
                              size='small'
                              color='default'
                              variant='soft'
                              sx={{ mr: 1 }}
                              onDelete={deleteSupplier(supplier._id)}
                            />
                          ))}
                        </InputAdornment>,
                    }}
                  />

                  <SuppliersModal
                    open={suppliersDrawer.value}
                    onClose={suppliersDrawer.onFalse}
                    selected={values.suppliers}
                    suppliers={suppliersFromDB}
                    addSuppliers={addSuppliers}
                    deleteSupplier={deleteSupplier}
                  />
                  <RHFTextField
                    select
                    name='requestType'
                    id='request-type-select'
                    label='Request Type *'
                  >
                    {requestTypeVariants.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </RHFTextField>

                  {isPartner &&
                    <RHFTextField
                      useSeparators
                      name='partnerFee'
                      label='Partner Fee'
                    />
                  }
                  <DetailsList
                    wrappedComponent={Stack}
                    btnTitle='Edit'
                    fields={fields}
                    btnConfig={{
                      onClick: editClickHandler,
                      disabled: !values.projectId,
                    }}
                    labelContainerSx={{
                      minWidth: 210,
                      width: 210,
                    }}
                    wrappedProps={{
                      sx: {
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        border: (theme: Theme) => `1px solid ${alpha(theme.palette.text.secondary, 0.2)}`,
                        borderRadius: 1.5,
                      },
                    }}
                  />
                  <RHFTextField
                    multiline
                    fullWidth
                    name='emailNote'
                    label='Email note'
                    variant='outlined'
                    minRows={3}
                  />
                </Stack>
              </CardContent>
            </Card>
          </Stack>

          <Stack gap={3} direction='row'>
            <Typography sx={{ minWidth: 344 }} variant='h6'>Documents</Typography>
            <Box sx={{ flex: 1 }}>
              <ProjectDocuments
                onAddDocumentsClickHandler={onAddDocumentsClickHandler}
                onRemoveUploadedFileClickHandler={onRemoveUploadedFileClickHandler}
                modalUploadFileHandler={modalUploadFileHandler}
                documentsModalList={documentsModalList}
                uploadedDocuments={values.uploadedDocuments}
              />
            </Box>
          </Stack>

          <Stack alignItems='flex-end'>
            <LoadingButton
              type='submit'
              variant='contained'
              size='large'
              loading={isSubmitting}
              disabled={isSendDisabled}
            >
              Send Request
            </LoadingButton>
          </Stack>

          <SuccessDrawer
            title='Request Sent Successfully!'
            successDrawer={successDrawer}
            onCloseClickHandler={onCloseClickHandler}
          />
          <EditDrawer
            editDrawer={editDrawer}
          />
        </Stack>
      </FormProvider>.
    </>
  );
};
