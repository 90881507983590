import _ from 'lodash';
import { defaultAssetsSections } from 'src/constants/demo-mock-data';
import { sectionKeyToValue } from 'src/features/report-page/edit-drawer/data';

import { AssetDocumentsType, AssetSection, DetailViewData, NoteSettingType, ReportKeyType, ReportSettingsType } from '../../../../types';

const desiredReportSectionsOrder = [
  'Gallery',
  'Project Summary',
  'Our Request',
  'Site & Purchase',
  'Proposed Units',
  'Construction',
  'Finance',
  'Timeline & Cashflow',
  'Sales',
  'Project Multiples',
  'Sensitivity Analysis',
  'Local Area',
  'Local Market',
  'Comparables',
];

export const getSettingConfig = (reportSettings: ReportSettingsType[], type: ReportKeyType, leadId: string, identifier?: string) => {
  const isSettingExist = reportSettings.find(setting =>
    setting.leadId === leadId
    && (identifier ? setting.identifier === identifier : true)
    && setting.reportName === type);
  return isSettingExist;
};

export const getDocumentsWithAttached = (finalFiles: AssetDocumentsType[], settings: ReportSettingsType) => {
  const updatedFiles: AssetDocumentsType[] = finalFiles.map(file => {
    return {
      ...file,
      attachedToAssetSection: {
        gallery: !!getNoteConfig(settings, 'Gallery')?.files?.find(noteFile => noteFile._id === file._id),
        aboutProject: !!getNoteConfig(settings, 'Project Summary')?.files?.find(noteFile => noteFile._id === file._id),
        ourRequest: !!getNoteConfig(settings, 'Our Request')?.files?.find(noteFile => noteFile._id === file._id),
        siteAndPurchase: !!getNoteConfig(settings, 'Site & Purchase')?.files?.find(noteFile => noteFile._id === file._id),
        proposedUnits: !!getNoteConfig(settings, 'Proposed Units')?.files?.find(noteFile => noteFile._id === file._id),
        construction: !!getNoteConfig(settings, 'Construction')?.files?.find(noteFile => noteFile._id === file._id),
        finance: !!getNoteConfig(settings, 'Finance')?.files?.find(noteFile => noteFile._id === file._id),
        sales: !!getNoteConfig(settings, 'Sales')?.files?.find(noteFile => noteFile._id === file._id),
        timelineAndCashflow: !!getNoteConfig(settings, 'Timeline & Cashflow')?.files?.find(noteFile => noteFile._id === file._id),
        sensitivityAnalysis: !!getNoteConfig(settings, 'Sensitivity Analysis')?.files?.find(noteFile => noteFile._id === file._id),
        localArea: !!getNoteConfig(settings, 'Local Area')?.files?.find(noteFile => noteFile._id === file._id),
        localMarket: !!getNoteConfig(settings, 'Local Market')?.files?.find(noteFile => noteFile._id === file._id),
        comparables: !!getNoteConfig(settings, 'Comparables')?.files?.find(noteFile => noteFile._id === file._id),
      },
    };
  });
  return updatedFiles;
};

export const getAllSectionsConfig = (settings: ReportSettingsType) => {
  return {
    gallery: getFullSectionConfig(settings, 'Gallery'),
    aboutProject: getFullSectionConfig(settings, 'Project Summary'),
    ourRequest: getFullSectionConfig(settings, 'Our Request'),
    siteAndPurchase: getFullSectionConfig(settings, 'Site & Purchase'),
    proposedUnits: getFullSectionConfig(settings, 'Proposed Units'),
    construction: getFullSectionConfig(settings, 'Construction'),
    finance: getFullSectionConfig(settings, 'Finance'),
    sales: getFullSectionConfig(settings, 'Sales'),
    timelineAndCashflow: getFullSectionConfig(settings, 'Timeline & Cashflow'),
    sensitivityAnalysis: getFullSectionConfig(settings, 'Sensitivity Analysis'),
    projectMultiples: getFullSectionConfig(settings, 'Project Multiples'),
    localArea: getFullSectionConfig(settings, 'Local Area'),
    localMarket: getFullSectionConfig(settings, 'Local Market'),
    comparables: getFullSectionConfig(settings, 'Comparables'),
  };
};

export const getFullSectionConfig = (settings: ReportSettingsType, sectionName: string): AssetSection => {
  return {
    note: getNoteConfig(settings, sectionName)?.note,
    isNoteActive: getNoteConfig(settings, sectionName)?.isActive,
    isActive: getSectionConfig(settings, sectionName)?.isActive,
  };
};

export const getSectionConfig = (settings: ReportSettingsType, sectionName: string) => {
  return settings.sections?.find(sec => sec.section === sectionName) || {
    note: '',
    isActive: true,
    files: [],
    section: sectionName,
  };
};
export const getNoteConfig = (settings: ReportSettingsType, sectionName: string) => {
  return settings.notes?.find(note => note.section === sectionName) || {
    note: '',
    isActive: true,
    files: [],
    section: sectionName,
  };
};

export const getReportSettingsFromDetailViewData = (leadId: string, data: DetailViewData, reportName: ReportKeyType, identifier: string = '') => {
  const filledObject = _.defaults({}, data.assets?.sections, defaultAssetsSections);
  const sections: NoteSettingType[] = Object.entries(filledObject).map(([section, values]) => ({
    section: sectionKeyToValue[section],
    isActive: values.isActive,
    note: section === 'aboutProject' ? data.assets?.marketPlaceListing?.description : values.note,
    isNoteActive: values.isNoteActive,
    files: data.assets?.documents?.filter(doc => doc.attachedToAssetSection?.[section as keyof typeof doc.attachedToAssetSection]),
  }));

  const sortedSections = _.sortBy(sections, (section) => {
    const index = desiredReportSectionsOrder.indexOf(section.section);
    return index === -1 ? desiredReportSectionsOrder.length : index;
  });

  const formattedData: ReportSettingsType = {
    leadId,
    identifier,
    reportName: reportName,
    files: data.assets?.documents,
    images: data.assets?.marketPlaceListing?.coverImages,
    sections: sortedSections,
    notes: sections.filter(sec => sec.section !== 'Gallery'),
    summaryText: data.assets?.marketPlaceListing?.description,
  };
  return formattedData;
};
