import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { ReceiptIcon, SendIcon } from 'src/assets';
import { Iconify, StyledTableRow } from 'src/components';
import { allChipColors } from 'src/constants/constants';
import { useBoolean } from 'src/hooks';
import { fDate, fNumberWithDefaultCurrency, fPercent } from 'src/utils';

import { MarketplaceProjectActivity } from '../../../../../../../../../types';
import { ActivityDrawer } from './activity-drawer';

type OfferingActivityTableRowProps = {
  row: MarketplaceProjectActivity;
  refetchFunction: VoidFunction;
}

export const OfferingActivityTableRow = (props: OfferingActivityTableRowProps) => {
  const {
    row,
    refetchFunction,
  } = props;
  const theme = useTheme();
  const drawer = useBoolean();

  const {
    status,
    commissionPercent,
    investorName,
    investorApproved,
    commission,
    investorEmail,
    lastUpdateName,
    projectName,
    reserved,
    secured,
    lastUpdate,
  } = row;

  const isReserved = status === 'Reserved';
  const isRejected = status === 'Rejected';
  const isCommitmentSigned = status === 'Commitment Signed';
  const isSecuredCapitalSigned = status === 'Secured Capital';


  return (
    <>
      <StyledTableRow hover>
        <TableCell>
          <Stack direction='row' gap={1} alignItems='center'>
            <Typography variant='body2' color='text.primary'>
              {investorName}
            </Typography>
            {investorApproved &&
              <Iconify
                color={theme.palette.success.main} icon='eva:checkmark-circle-2-fill'
              />
            }
          </Stack>
          <Typography variant='caption' color={theme.palette.text.secondary}>
            {investorEmail}
          </Typography>
        </TableCell>
        <TableCell>
          {projectName}
        </TableCell>

        <TableCell>
          <Typography
            variant='body2'
            color={(isReserved || isRejected)
              ? theme.palette.text.secondary
              : theme.palette.text.primary}
          >
            {fNumberWithDefaultCurrency(reserved)}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant='body2' color='text.primary'>
            {fNumberWithDefaultCurrency(secured)}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant='body2'
            color={(isReserved || isRejected) ? theme.palette.text.secondary : theme.palette.text.primary}>
            {fNumberWithDefaultCurrency(commission)}
          </Typography>
          <Typography variant='caption' color={theme.palette.text.secondary}>
            {fPercent(commissionPercent)}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant='body2' color='text.primary'>
            {fDate(lastUpdate)}
          </Typography>
          <Typography variant='caption' color={theme.palette.text.secondary}>
            {lastUpdateName}
          </Typography>
        </TableCell>

        <TableCell>
          <Chip
            size='small'
            variant='outlined'
            color={allChipColors[status] ?? 'default'}
            label={status}
          />
        </TableCell>

        <TableCell align='right'>
          <>
            {isReserved &&
              <Button
                onClick={drawer.onTrue}
                variant='outlined'
                size='small'
              >
                Confirm
              </Button>
            }
            {isCommitmentSigned &&
              <Button
                onClick={drawer.onTrue}
                startIcon={<SendIcon/>}
                variant='outlined'
                size='small'
              >
                Invoice
              </Button>
            }
            {isSecuredCapitalSigned &&
              <Button
                onClick={drawer.onTrue}
                startIcon={<ReceiptIcon/>}
                variant='outlined'
                size='small'
              >
                Receipt
              </Button>
            }
          </>
        </TableCell>
      </StyledTableRow>

      <ActivityDrawer
        isPartnerActivity
        refetchFunction={refetchFunction}
        drawer={drawer}
        activity={row}
      />
    </>
  );
};
