import ImageIcon from '@mui/icons-material/Image';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { DrawerCustom, getFileConfig, Iconify, ListItemCustom, UploadedFilesType } from 'src/components';
import { paths } from 'src/config';
import { UseBooleanReturnType } from 'src/hooks';
import { extractFileExtension, openInNewTab } from 'src/utils';

import { CompanyPartnerType, PartnerItemType } from '../../../../../../../../types';

interface DetailsDrawerProps {
  drawer: UseBooleanReturnType;
  requestType: CompanyPartnerType;
  supplier: PartnerItemType;
  itemizedFiles: UploadedFilesType[];
  detailedFiles: UploadedFilesType[];
  offerFiles: UploadedFilesType[];
  contractFiles: UploadedFilesType[];
}

export const DetailsDrawer = (props: DetailsDrawerProps) => {
  const {
    drawer,
    supplier,
    requestType,
    itemizedFiles,
    offerFiles,
    detailedFiles,
    contractFiles,
  } = props;

  const list = useMemo(() => {
    return [
      {
        label: 'Offer',
        files: offerFiles,
      },
      {
        label: 'Itemized Quotation',
        files: itemizedFiles,
      },
      {
        label: 'Detailed Quotation',
        files: detailedFiles,
      },
      {
        label: 'Term Sheet',
        files: contractFiles,
      },
    ];
  }, [contractFiles, detailedFiles, itemizedFiles, offerFiles]);

  const onIconClickHandler = (file: UploadedFilesType) => () => {
    openInNewTab(file.url);
  };

  return (
    <>
      <DrawerCustom
        open={drawer.value}
        onCloseDrawerHandler={drawer.onFalse}
        headChildren={
          <Stack
            width='100%'
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            gap={2}
          >
            <Typography variant='h6'>Uploaded Documents</Typography>
            <IconButton
              onClick={drawer.onFalse}
            >
              <Iconify icon='mingcute:close-line' width={18}/>
            </IconButton>
          </Stack>
        }
        bodyChildren={
          <Stack gap={3}>
            <ListItemCustom
              showAvatar
              key={supplier._id}
              avatarSrc={supplier.logoUrl}
              avatarSx={{ borderRadius: 1.5 }}
              avatarAlt={supplier.companyName}
              primaryText={supplier.companyName}
              secondaryText={requestType}
              secondaryChildren={
                <>
                  <Link
                    underline='none'
                    target='_blank'
                    href={paths.supplierPartners.products(supplier._id)}
                  >
                    Profile
                  </Link>
                </>
              }
            />
            {list.map((listItem) => {
              if (!listItem.files.length) return null;
              return (
                <Stack key={listItem.label} gap={1.5}>
                  <Typography variant='subtitle2'>
                    {listItem.label}
                  </Typography>
                  {listItem.files.map((file) => {
                    return (
                      <Stack
                        key={file._id}
                        spacing={1}
                        direction='row'
                        alignItems='center'
                        sx={{ cursor: 'pointer' }}
                        onClick={onIconClickHandler(file)}
                      >
                        <ListItemIcon
                          sx={{
                            mr: 0,
                            cursor: 'pointer',
                          }}
                        >
                          {
                            getFileConfig(file.filetype).icon
                            || <ImageIcon
                              style={{
                                width: 40,
                                height: 40,
                              }}
                            />
                          }
                        </ListItemIcon>
                        <ListItemText
                          primary={`${file?.sectionFilename}${extractFileExtension(file?.filename)}`}
                          secondary={file.uploadedBy}
                          secondaryTypographyProps={{ variant: 'caption' }}
                          sx={{
                            '& .MuiTypography-body1': {
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            },
                          }}
                        />
                        {file.uploadedAt &&
                          <Typography variant='body2'>{file.uploadedAt}</Typography>
                        }
                      </Stack>
                    );
                  })}
                </Stack>
              );
            })}
          </Stack>
        }
      />
    </>
  );
};
