import { Box, Button, IconButton, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CrossIcon } from 'src/assets/svg';
import { CustomSelect, DrawerCustom, InlineBadge, ListItemCustom, MessageWithHighlight, StepperCustom, StepType } from 'src/components';
import { useBoolean } from 'src/hooks';
import { RootState } from 'src/store';
import { loadNotifications, readNotifications } from 'src/store/storage';
import { fDate } from 'src/utils';

import { NotificationType } from '../../../../../types';

const sections = ['All sections', 'Lead', 'Research', 'Project Preparation', 'Tendering', 'Debt', 'Equity', 'Sell'];

export interface StepperBacklogProps {
  steps: StepType[];
  leadId?: string;
  projectId?: string;
}

export const StepperBacklog = (props: StepperBacklogProps) => {
  const {
    steps,
    leadId,
    projectId,
  } = props;

  const [sectionFilter, setSectionFilter] = useState<string>('All sections');
  const disableBacklog = useSelector((state: RootState) => state.App.config?.disableBacklog);

  const backlogItems = useSelector((state: RootState) => state.Storage.history);
  const newHistoryNotifications = backlogItems.filter(item => !item.isDone);
  const newHistoryNotificationsCount = newHistoryNotifications.length;

  const backlogDrawer = useBoolean();
  const dispatch = useDispatch();

  useEffect(() => {
    if (leadId) {
      dispatch(loadNotifications({
        notificationType: 'logs',
        leadId,
        projectId,
      }));
    }
  }, [dispatch, leadId, projectId]);

  const handleSectionFiler = useCallback((event: SelectChangeEvent<string[]>) => {
    const newSectionFilter = event.target.value;
    setSectionFilter(newSectionFilter as string);
  }, []);

  const filteredBacklogItems = backlogItems.filter(item =>
    (sectionFilter === 'All sections' || item.section === sectionFilter),
  );

  const onNotificationClickHandler = (notification: NotificationType) => () => {
    if (notification.isDone) return;
    dispatch(readNotifications('logs', [notification._id], leadId, projectId));
  };

  return (
    <>
      <Stack
        direction='row'
        sx={{
          padding: 3,
          typography: 'body2',
        }}
        alignItems='center'
      >
        <StepperCustom
          steps={steps}
        />
        <Button
          disabled={disableBacklog}
          size='large'
          variant='outlined'
          onClick={backlogDrawer.onTrue}
        >
          History
          <InlineBadge
            value={newHistoryNotificationsCount}
            textColor={disableBacklog ? '#A0A0A0' : undefined}
            background={disableBacklog ? '#E0E0E0' : undefined}
          />
        </Button>
      </Stack>
      <DrawerCustom
        open={backlogDrawer.value}
        onCloseDrawerHandler={backlogDrawer.onFalse}
        headChildren={
          <>
            <Typography variant='h6'>History</Typography>
            <IconButton onClick={backlogDrawer.onFalse}>
              <CrossIcon/>
            </IconButton>
          </>
        }
        bodyChildren={
          <Stack gap={2.5} width={480} maxWidth={480}>
            <Stack gap={2} direction='row' px={3}>
              <CustomSelect
                controlSx={{ width: '100%' }}
                value={[sectionFilter]}
                handleSelectOnChange={handleSectionFiler}
                options={sections}
              />
            </Stack>
            <Stack>
              {filteredBacklogItems.map((backlogItem) => {
                return (
                  <ListItemCustom
                    showAvatar
                    onItemClick={onNotificationClickHandler(backlogItem)}
                    key={backlogItem._id}
                    listItemSx={{
                      borderBottom: (theme) => `1px dashed ${theme.palette.divider}`,
                      px: 3,
                    }}
                    avatarSrc={backlogItem.logoUrl}
                    primaryText={
                      <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Stack direction='row'>
                          <MessageWithHighlight message={backlogItem.message}/>
                        </Stack>
                        {!backlogItem.isDone &&
                          <Box
                            component='span'
                            sx={{
                              width: 10,
                              height: 10,
                              minWidth: 10,
                              borderRadius: '50%',
                              bgcolor: (theme) => theme.palette.error.main,
                            }}
                          />}
                      </Stack>
                    }
                    secondaryChildren={
                      <Stack direction='row' gap={0.5} alignItems='center'>
                        <Typography
                          variant='body2'
                          sx={{
                            component: 'span',
                            color: 'text.disabled',
                            typography: 'caption',
                          }}
                        >
                          {fDate(backlogItem.date)}
                        </Typography>
                        <Box
                          component='span'
                          sx={{
                            width: 2,
                            height: 2,
                            borderRadius: '50%',
                            bgcolor: 'text.disabled',
                          }}
                        />
                        <Typography
                          variant='body2'
                          sx={{
                            component: 'span',
                            color: 'text.disabled',
                            typography: 'caption',
                          }}
                        >
                          {backlogItem.section}
                        </Typography>
                      </Stack>
                    }
                  />
                );
              })}
            </Stack>
          </Stack>
        }
        bodyChildrenSx={{
          pt: 0,
          px: 0,
        }}
      />
    </>
  );
};
