import { AssigneeType } from '../../../../../types';
import { FinRequestInfo, FinRequestInfoAboutProps } from './components';

type FinRequestProps = FinRequestInfoAboutProps & {
  projectManager?: AssigneeType;
}

export const FinRequest = (props: FinRequestProps) => {
  return (
    <FinRequestInfo {...props}/>
  );
};
