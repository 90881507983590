import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'src/store';
import { loadProductDetails } from 'src/store/partners';

import { SupplierProductForm } from './supplier-product-form';

const EditSupplierProduct = () => {
  const { id, productId } = useParams<{ id: string, productId: string }>();
  const dispatch = useDispatch();
  const product = useSelector((state: RootState) => state.Partners.currentProduct);

  useEffect(() => {
    dispatch(loadProductDetails(productId));
  }, [dispatch, productId]);

  return (
    <SupplierProductForm partnerId={id} currentProduct={product}/>
  );
};

export default EditSupplierProduct;
