import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { memo } from 'react';
import { ListItemCustom, MoreOptions, OptionType, StyledTableRow } from 'src/components';
import { useBoolean, usePopover } from 'src/hooks';
import { fPercent } from 'src/utils';

import { QuotationType } from '../../../../../../../../types';
import { UploadFormDrawer, UploadFormDrawerSubmit } from '../quotations/upload-form-drawer';

interface QuotationRequestTableRowProps {
  row: QuotationType;
  deleteOnClickHandler: (quotationId: string) => void;
  addOfferSubmitFunction: (data: UploadFormDrawerSubmit) => void;
}

export const QuotationRequestTableRow = memo((props: QuotationRequestTableRowProps) => {
  const {
    row,
    deleteOnClickHandler,
    addOfferSubmitFunction,
  } = props;
  const theme = useTheme();

  const {
    partnerId,
    fee,
    requestType,
    _id,
  } = row;

  const {
    companyName,
    companySubType,
    companyContactName,
    companyContactPhone,
    companyContactEmail,
    logoUrl,
    designation,
  } = partnerId;

  const popover = usePopover();

  const deleteWrapper = () => {
    popover.onClose();
    deleteOnClickHandler(row._id);
  };

  const moreOptions: OptionType[] = [
    {
      label: 'Resend',
      icon: 'send',
      closePopover: popover.onClose,
      itemOnClickHandler: () => console.log('todo: resend'),
    },
    {
      label: 'Delete',
      icon: 'delete',
      color: 'error.main',
      closePopover: popover.onClose,
      itemOnClickHandler: deleteWrapper,
    },
  ];

  const addDrawer = useBoolean();
  const onAddOfferClick = () => {
    addDrawer.onTrue();
  };
  const onAddOfferSubmit = (data: UploadFormDrawerSubmit) => {
    addDrawer.onFalse();
    addOfferSubmitFunction(data);
  };

  return (
    <>
      <StyledTableRow hover>
        <TableCell>
          <ListItemCustom
            showAvatar
            key={_id}
            avatarSrc={logoUrl}
            avatarSx={{ borderRadius: 1.5 }}
            listItemTextProps={{ primaryTypographyProps: { typography: 'body2' } }}
            secondaryTextTypographySx={{
              component: 'span',
              typography: 'caption',
              color: 'text.secondary',
            }}
            avatarAlt={companyName}
            primaryText={companyName}
            secondaryText={companySubType?.join(', ')}
          />
        </TableCell>

        <TableCell>
          <ListItemText
            disableTypography
            primary={
              <Typography variant='body2'>
                {requestType}
              </Typography>
            }
            secondary={
              <Typography
                noWrap
                variant='caption'
                color={theme.palette.text.secondary}>
                {fPercent(fee)}
              </Typography>
            }
          />
        </TableCell>

        <TableCell>
          <ListItemText
            disableTypography
            primary={
              <Typography variant='body2'>
                {companyContactName}
              </Typography>
            }
            secondary={
              <Typography
                noWrap
                variant='caption'
                color={theme.palette.text.secondary}>
                {designation}
              </Typography>
            }
          />
        </TableCell>

        <TableCell>
          <ListItemText
            disableTypography
            primary={
              <Typography variant='body2'>
                {companyContactEmail}
              </Typography>
            }
            secondary={
              <Typography
                noWrap
                variant='caption'
                color={theme.palette.text.secondary}>
                {companyContactPhone}
              </Typography>
            }
          />
        </TableCell>

        <TableCell
          size='small'
          padding='none'
          align='right'
          sx={{
            px: 1,
            whiteSpace: 'nowrap',
          }}
        >
          <Stack direction='row' alignItems='center'>
            <Button
              sx={{ whiteSpace: 'nowrap' }}
              variant='outlined'
              size='small'
              onClick={onAddOfferClick}
            >
              Add Offer
            </Button>

            <MoreOptions
              options={moreOptions}
              popoverOnClose={popover.onClose}
              popoverOnOpen={popover.onOpen}
              popoverOpen={popover.open}
            />
          </Stack>
        </TableCell>
      </StyledTableRow>

      <UploadFormDrawer
        showIsCalculate
        drawer={addDrawer}
        requestType={requestType}
        supplier={partnerId}
        onSubmit={onAddOfferSubmit}
        title='Quotation Offer'
        buttonText='Add Offer'
      />
    </>
  );
});
