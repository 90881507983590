import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormComponent, NoLayout, UploadedFilesType, UploadFile, UploadFileProps } from 'src/components';
import { ConfigType, LayoutTypes } from 'src/features/new-lead';

import { FinanceUploadFileType, FinancialTableType, TermSheetType } from '../../../../../types';

export interface UploadTermSheetDrawerProps {
  item?: FinancialTableType;
  closeDrawerHandler: VoidFunction;
  submitFunction: (item: FinancialTableType, file: FinanceUploadFileType) => void;
}

export const UploadTermSheetDrawer = (props: UploadTermSheetDrawerProps) => {
  const {
    item,
    closeDrawerHandler,
    submitFunction,
  } = props;
  const {
    control,
    handleSubmit,
    watch,
    setValue,
  } = useForm<TermSheetType>();
  const [file] = watch(['file']);

  const uploadFileHandler = useCallback((files: UploadedFilesType[]) => {
    setValue('file', files.length > 1 ? files[1] : files[0]);
  }, [setValue]);

  const uploadTermSheetConfig: ConfigType<TermSheetType, UploadFileProps, LayoutTypes>[] = useMemo(() => {
    return [
      {
        rightBlock: [
          {
            name: 'file',
            correspondingComponent: UploadFile,
            uploadedFiles: file ? [file] : [],
            uploadFileHandler,
            section: 'Financing',
            containerSx: { width: '100%' },
          },
        ],
        layout: NoLayout,
        stackConfig: {
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 2,
          rowGap: 3,
          pl: 0,
        },
      },
    ];
  }, [file, uploadFileHandler]);

  const onSubmit = (data: TermSheetType) => {
    if (item && item.termSheet && data.file) {
      const file: FinanceUploadFileType = {
        ...data.file,
        badgeTitle: 'Term Sheet',
        uploaded: true,
        badgeColor: 'info',
      };
      submitFunction(item, file);
      closeDrawerHandler();
    }
  };

  return (
    <>
      <FormComponent
        onSubmitHandler={handleSubmit(onSubmit)}
        formConfig={uploadTermSheetConfig}
        btnTitle='Confirm'
        control={control}
        formStackProps={{
          p: 0,
          height: '100%',
          justifyContent: 'space-between',
        }}
        configStackWrapperProps={{ p: 0 }}
        btnConfig={{
          fullWidth: true,
          variant: 'contained',
        }}
      />
    </>
  );
};
