import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { SxProps, Theme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { ChangeEvent, memo } from 'react';
import { useSelector } from 'react-redux';
import { TableHeadType } from 'src/features/leads-page';
import { RootState } from 'src/store';

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const;

type TableHeadCustomProps = {
  order?: 'asc' | 'desc';
  orderBy?: string;
  headLabel: TableHeadType[];
  rowCount?: number;
  numSelected?: number;
  onSort?: (id: string) => void;
  onSelectAllRows?: (checked: boolean) => void;
  sx?: SxProps<Theme>;
};

export const TableHeadCustom = memo((props: TableHeadCustomProps) => {
  const {
    order,
    orderBy,
    rowCount = 0,
    headLabel,
    numSelected = 0,
    onSort,
    onSelectAllRows,
    sx,
  } = props;
  return (
    <TableHead sx={sx}>
      <TableRow>
        {onSelectAllRows && (
          <TableCell padding='checkbox'>
            <Checkbox
              indeterminate={!!numSelected && numSelected < rowCount}
              checked={!!rowCount && numSelected === rowCount}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onSelectAllRows(event.target.checked)
              }
            />
          </TableCell>
        )}

        {headLabel.map((headCell, index) => {
          const headId = headCell._id;
          if (headCell.hidden) return null;
          return (
            <TableCell
              key={index}
              align={headCell.align || 'left'}
              sortDirection={orderBy === headId ? order : false}
              sx={{
                whiteSpace: 'nowrap',
                width: headCell.width,
              }}
            >
              {(onSort && headId) ? (
                <TableSortLabel
                  hideSortIcon
                  active={orderBy === headId}
                  direction={orderBy === headId ? order : 'asc'}
                  onClick={() => onSort(headId)}
                >
                  {headCell.label}
                  {orderBy === headId ? (
                    <Box sx={{ ...visuallyHidden }}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
});
