import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import _, { omit } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, RHFAutocomplete, RHFMultiCheckbox, RHFTextField } from 'src/components';
import { RHFDatePicker } from 'src/components/';
import { distributionCheckboxesListForAsset, slicedDistributionCheckboxesList } from 'src/constants/constants';
import { _projectTypeList } from 'src/features/projects-offering-page';
import { useScrollToError } from 'src/hooks/';
import { RootState } from 'src/store';
import { deleteDocument } from 'src/store/financial';
import { createLead } from 'src/store/lead';
import { deleteCoverImageThunk, setImageAsCoverThunk, updateAsset, uploadCoverImageToAsset } from 'src/store/storage';
import { convertToOnlyNumber, extractFileExtension } from 'src/utils';
import * as Yup from 'yup';

import { Asset, DetailViewData, MarketPlaceListingAssetType } from '../../../../../../types';
import { Listing } from './listing';

export type MergedType = Omit<Asset, '_id' | 'marketPlaceListing' | 'status' | 'createdAt' | 'updatedAt'> & {
  distribution: string[];
  marketPlaceListing?: MarketPlaceListingAssetType & {
    buyerFeeAmount?: string;
  }
}

export interface GeneralProps {
  detailsData: DetailViewData;
  setFormGetter?: (getter: () => MergedType) => void;
}

export const General = (props: GeneralProps) => {
  const {
    setFormGetter,
    detailsData,
  } = props;

  const reducedMenu = useSelector((state: RootState) => state.App.config?.reducedMenu);
  const partnerId = useSelector((state: RootState) => state.Auth.user?.partnerId || '');
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const assets = detailsData.assets;
  const lead = detailsData.lead;

  const AssetSchema: Yup.ObjectSchema<MergedType> = Yup.object().shape({
    documents: Yup.array(),
    distribution: Yup.array().of(Yup.string().required()).required().test(
      'distribution',
      'Choose at least one option except Research and Project Preparation',
      (value) => Boolean(_.intersection(slicedDistributionCheckboxesList.map(item => item.value), value).length),
    ),
    general: Yup.object().shape({
      projectName: Yup.string().required('Project Name is required'),
      projectType: Yup.string().oneOf(_projectTypeList, 'Project Type is required').required('Project Type is required'),
      offeringClosingDate: Yup.string().required('Offering Estimated Closing Date is required'),
    }),
    marketPlaceListing: Yup.object().shape({
      description: Yup.string().required('Description is required').test(
        'description',
        'Description is required',
        (value) => {
          const tempElement = document.createElement('div');
          tempElement.innerHTML = value;
          return !!tempElement.textContent?.trim();
        },
      ),
      buyerFeeAmount: Yup.string().optional().test('buyerFeeAmount', 'Buyer\'s fee is required', (value, { from }) => {
        /* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars, no-unused-vars*/
        const [_, parent2] = from as any;
        const distribution = parent2.value.distribution;
        if (!distribution?.includes('sell')) return true;
        return (convertToOnlyNumber(value) || 0) >= 0;
      }),
      coverImages: Yup.array().required().min(1, 'Upload at least one cover image'),
    }),
    investing: Yup.object().shape({}),
    emailTemplate: Yup.object().shape({}),
    sections: Yup.object().shape({}),
  });

  const defaultValues: MergedType = useMemo(() => ({
    distribution: lead?.distribution ? _.chain(lead?.distribution)
      .pickBy(value => value)
      .keys()
      .value() : ['research', 'projectPreparation'],
    general: {
      projectName: assets?.general?.projectName || '',
      projectType: lead?.projectType || null,
      offeringClosingDate: assets?.general?.offeringClosingDate || '',
    },
    marketPlaceListing: {
      description: assets?.marketPlaceListing?.description || '',
      coverImages: assets?.marketPlaceListing?.coverImages || [],
      documents: assets?.documents?.map(document => ({
        ...document,
        size: document.filesize,
        filename: document.sectionFilename + extractFileExtension(document.filename),
      })).filter(doc => doc.size) || [],
      coverImagesToDelete: assets?.marketPlaceListing?.coverImagesToDelete || [],
      documentsToDelete: assets?.marketPlaceListing?.documentsToDelete || [],
      newCoverImageId: assets?.marketPlaceListing?.newCoverImageId || undefined,
      tags: assets?.marketPlaceListing?.tags || [],
      buyerFeeAmount: lead?.buyerFeeAmount?.toString() || '0',
      displayRaiseTarget: assets?.marketPlaceListing?.displayRaiseTarget || false,
      displayEstimatedClosingDate: assets?.marketPlaceListing?.displayEstimatedClosingDate || false,
      displayFullReport: assets?.marketPlaceListing?.displayFullReport || false,
      displayPmReport: assets?.marketPlaceListing?.displayPmReport || false,
      displayLenderReport: assets?.marketPlaceListing?.displayLenderReport || false,
      isDocumentDownloadAllowed: assets?.marketPlaceListing?.isDocumentDownloadAllowed || false,
    },
    investing: {
      minInvestmentAmount: assets?.investing?.minInvestmentAmount || undefined,
      maxInvestmentAmount: assets?.investing?.maxInvestmentAmount || undefined,
      legalDocuments: assets?.investing?.legalDocuments || undefined,
      additionalStatements: assets?.investing?.additionalStatements || undefined,
    },
    emailTemplate: {
      equityOfferTemplate: assets?.emailTemplate?.equityOfferTemplate || '',
      debtOfferTemplate: assets?.emailTemplate?.debtOfferTemplate || '',
      fullBuyoutOfferTemplate: assets?.emailTemplate?.fullBuyoutOfferTemplate || '',
    },
    documents: assets?.documents,
  }), [lead?.distribution, lead?.projectType, lead?.buyerFeeAmount, assets?.general?.projectName, assets?.general?.offeringClosingDate, assets?.marketPlaceListing?.description, assets?.marketPlaceListing?.coverImages, assets?.marketPlaceListing?.coverImagesToDelete, assets?.marketPlaceListing?.documentsToDelete, assets?.marketPlaceListing?.newCoverImageId, assets?.marketPlaceListing?.tags, assets?.marketPlaceListing?.displayRaiseTarget, assets?.marketPlaceListing?.displayEstimatedClosingDate, assets?.marketPlaceListing?.displayFullReport, assets?.marketPlaceListing?.displayPmReport, assets?.marketPlaceListing?.displayLenderReport, assets?.marketPlaceListing?.isDocumentDownloadAllowed, assets?.documents, assets?.investing?.minInvestmentAmount, assets?.investing?.maxInvestmentAmount, assets?.investing?.legalDocuments, assets?.investing?.additionalStatements, assets?.emailTemplate?.equityOfferTemplate, assets?.emailTemplate?.debtOfferTemplate, assets?.emailTemplate?.fullBuyoutOfferTemplate]);

  const methods = useForm<MergedType>({
    resolver: yupResolver(AssetSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: {
      isSubmitting,
      errors,
    },
  } = methods;
  useScrollToError(errors);

  useEffect(() => {
    if (assets?._id) {
      reset(defaultValues);
    }
  }, [assets?._id, defaultValues, reset]);

  useEffect(() => {
    setFormGetter?.(methods.getValues);
  }, [setFormGetter, methods]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const {
        marketPlaceListing,
        general,
        distribution,
        documents,
      } = data;
      marketPlaceListing?.coverImagesToDelete?.forEach(file => {
        !file.file && dispatch(deleteCoverImageThunk(file._id));
      });
      marketPlaceListing?.newCoverImageId && dispatch(setImageAsCoverThunk(marketPlaceListing?.newCoverImageId));
      if (assets?._id) {
        marketPlaceListing?.coverImages?.map(file => file.file && uploadCoverImageToAsset(assets._id, file, partnerId));
        dispatch(createLead({
          lead: {
            ...omit(lead, 'status'),
            buyerFeeAmount: convertToOnlyNumber(marketPlaceListing?.buyerFeeAmount),
            projectType: general?.projectType,
            distribution: {
              research: distribution?.includes('research'),
              projectPreparation: distribution?.includes('projectPreparation'),
              tendering: distribution?.includes('tendering'),
              debtFinancing: distribution?.includes('debtFinancing'),
              equityFinancing: distribution?.includes('equityFinancing'),
              sell: distribution?.includes('sell'),
            },
          },
        }));
        dispatch(updateAsset({
          ...assets,
          documents,
          general: {
            ...assets?.general,
            ...omit(general, 'projectType'),
          },
          marketPlaceListing: {
            ...assets?.marketPlaceListing,
            ...omit(marketPlaceListing, 'buyerFeeAmount'),
          },
        }));
      }
      for (const doc of marketPlaceListing?.documentsToDelete || []) {
        if (doc._id) {
          await deleteDocument(doc._id, partnerId, true);
        }
      }
      enqueueSnackbar('Assets updated!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    } catch (error) {
      console.error(error);
    }
  });

  const generalDetails = (
    <Card sx={{ flex: 1 }}>
      <CardHeader title='General Details' />
      <CardContent>
        <Stack gap={3}>
          <RHFTextField
            name='general.projectName'
            label='* Project Name'
          />
          <RHFAutocomplete
            name='general.projectType'
            label='* Type of project'
            options={_projectTypeList}
          />
          <RHFDatePicker
            name='general.offeringClosingDate'
            label='* Offering Estimated Closing Date'
          />
        </Stack>
      </CardContent>
    </Card>
  );

  const generalSettings = (
    <Grid item xs={12} md={8}>
      <Card>
        <Stack spacing={1} sx={{ p: 3 }}>
          <Typography variant='subtitle2'>Publish in *</Typography>
          <Typography variant='caption'>
            The selected items will determine in which sections this project will be displayed and processed
            by team
            members. This is critically important and a mandatory field. The project can be for sale,
            funding only,
            tendering, or all options.
          </Typography>
          <RHFMultiCheckbox
            row
            spacing={4}
            name='distribution'
            options={distributionCheckboxesListForAsset(reducedMenu || false)}
          />
        </Stack>
      </Card>
    </Grid>
  );

  const renderActions = (
    <LoadingButton
      sx={{ ml: 'auto' }}
      type='submit'
      variant='contained'
      size='large'
      loading={isSubmitting}
    >
      Save Changes
    </LoadingButton>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack gap={3}>
        {generalDetails}
        <Listing
          methods={methods}
        />
        {generalSettings}
        {renderActions}
      </Stack>
    </FormProvider>
  );
};
