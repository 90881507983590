import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TabContextCustom } from 'src/components';
import { Overview } from 'src/features/marketplace-details/components/project-details/components/overview/overview';
import { Reports } from 'src/features/marketplace-details/components/project-details/components/reports/reports';
import { useRouter } from 'src/hooks';
import { RootState } from 'src/store';
import { getOfferingDetail } from 'src/store/partners-portal';
import { capitalizeFirstLetter } from 'src/utils';

import { DetailViewData } from '../../../../../../types';
import { Documents } from './documents/documents';
import { TABS } from './mock-data';
import { InvestorProjectDetailsHeader } from './project-details-header';

export interface InvestorProjectDetailsProps {
  showBackArrow?: boolean;
  isPreview?: boolean;
  customBackArrowClick?: VoidFunction;
  customDetailsData?: DetailViewData;
}

const InvestorProjectDetails = (props: InvestorProjectDetailsProps) => {
  const {
    showBackArrow,
    isPreview,
    customDetailsData,
    customBackArrowClick,
  } = props;
  const {
    tab,
    id,
  } = useParams<{ id: string, tab: string }>();
  const router = useRouter();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<string>(isPreview ? 'overview' : tab);
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const partnerId = useSelector((state: RootState) => state.Auth.user?.partnerId || '');
  const offeringDetails = customDetailsData?.offeringDetails || detailsData.offeringDetails;
  const files = offeringDetails?.documents;
  const fundingOverview = offeringDetails?.fundingOverview;

  useEffect(() => {
    if (!isPreview) {
      dispatch(getOfferingDetail(id, partnerId));
    }
  }, [dispatch, id, isPreview, partnerId]);

  const openDocumentsClickHandler = useCallback(() => {
    setCurrentTab('Documents');
  }, []);

  const openDetailedProjectClickHandler = useCallback(() => {
    setCurrentTab('Reports');
  }, []);

  const tabPanels = useMemo(() => {
    return [
      {
        tabKey: 'Overview',
        component: <Overview
          showOverviewButtons
          showInvestmentTarget
          showAddress
          showContactExpertButton
          isPreview={isPreview}
          detailsData={customDetailsData || detailsData}
          totalValue={fundingOverview?.totalRequired}
          investedValue={fundingOverview?.securedAmount}
          openDocumentsClickHandler={openDocumentsClickHandler}
          openDetailedProjectClickHandler={openDetailedProjectClickHandler}
        />,
      },
      {
        tabKey: 'Reports',
        component: <Reports/>,
      },
      {
        tabKey: 'Documents',
        component: <Documents files={files}/>,
      },
    ];
  }, [customDetailsData, detailsData, files, fundingOverview?.securedAmount, fundingOverview?.totalRequired, isPreview, openDetailedProjectClickHandler, openDocumentsClickHandler]);

  const handleTabOnChange = (_: SyntheticEvent, value: string) => {
    const low = value.toLowerCase();
    setCurrentTab(low);
    if (!isPreview) {
      router.replace(low);
    }
  };

  useEffect(() => {
    if (!isPreview) {
      setCurrentTab(tab);
    }
  }, [isPreview, tab]);

  return (
    <>
      <InvestorProjectDetailsHeader
        isPreview={isPreview}
        showBackArrow={showBackArrow}
        detailsData={customDetailsData || detailsData}
        customBackArrowClick={customBackArrowClick}
      />
      <TabContextCustom
        tabs={TABS}
        defaultTabValue='Overview'
        handleTabOnChange={handleTabOnChange}
        tabsSX={{ pl: 0 }}
        tabPanels={tabPanels}
        tabPanelsSx={{ px: 0 }}
        currentTabValue={capitalizeFirstLetter(currentTab.toLowerCase())}
      />
    </>
  );
};

export default InvestorProjectDetails;
