import { alpha, Box, Card, Link, Stack, TextFieldProps, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { StackProps } from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import _, { debounce } from 'lodash';
import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { DeclineBankIcon, SuccessfulBankIcon } from 'src/assets/svg';
import {
  CrumbType,
  DetailsList,
  DetailsListProps,
  FieldType,
  FormComponent,
  ListItemCustom,
  ProjectToolbar,
  RequestFile,
  RequestFileProps,
  TableCustom,
  TableCustomProps,
  useFinanceResult,
} from 'src/components';
import { paths } from 'src/config';
import { allChipColors, DEFAULT_CURRENCY, UNSPECIFIED_VALUE } from 'src/constants/constants';
import { useBoolean, useRouter } from 'src/hooks';
import { RootState } from 'src/store';
import { addPreliminaryDebtRequest, getDebtRequestDetails, getDocumentsList, loadFinancialData, updateDebtRequest } from 'src/store/financial';
import { getPartners } from 'src/store/partners';
import { fNumber, fPercent, openInNewTab, researchClickHelper, showEmptyValue } from 'src/utils';

import {
  BankType,
  ChipColorsType,
  DebtDetailsType,
  DebtType,
  DeptNameType,
  DocumentListBadgeType,
  FinanceCostsBreakdown,
  FinanceUploadFileType,
  FinancialStepType,
  FinancialTableType,
} from '../../../../types';
import { ConfigType, defaultStackConfig, LayoutTypes, RightBlockType } from '../new-lead';
import { DebtModal, DebtRequestRow, EditDrawer, InfoModal, LeftBlockComponent } from './components';
import { FieldsTypes, TABLE_HEAD } from './mock-data';

export interface DebtRequestType {
  costsBreakdown: FinanceCostsBreakdown[],
  documentList: FinanceUploadFileType[];
  seniorDebt?: DebtType;
  juniorDebt?: DebtType;
  bridgeDebt?: DebtType;
  otherDebt?: DebtType;
}

export type DebtName = 'seniorDebt' | 'juniorDebt' | 'bridgeDebt' | 'otherDebt'

export type DebtMapItem = {
  label: string,
  debtName: DebtName,
  detailsListName: keyof DebtRequestType,
  notesName: keyof DebtRequestType,
  selectedBanks?: BankType[],
}

export interface DebtRequestProps {
  isEditEnable?: boolean;
  showRequestAbility?: boolean;
  uploadedDocuments?: FinanceUploadFileType[];
  debts?: FinancialTableType[];
  crumbs?: CrumbType[];
  badgeTitleForNew?: DocumentListBadgeType;
  badgeColorForNew?: ChipColorsType;
  badgeColorForUploaded?: ChipColorsType;
  uploadDocTitle: string;
  uploadDocSubTitle: string;
  additionalSubmitFunction?: (documentList: FinanceUploadFileType[]) => void;
}

export const DebtRequest = (props: DebtRequestProps) => {
  const {
    isEditEnable = false,
    debts = [],
    uploadedDocuments,
    crumbs = [],
    uploadDocTitle,
    uploadDocSubTitle,
    badgeTitleForNew,
    badgeColorForNew,
    badgeColorForUploaded,
    additionalSubmitFunction,
    showRequestAbility,
  } = props;

  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const debtId = searchParams.get('debtId') || '';

  const router = useRouter();
  const {
    value,
    onToggle,
    onFalse,
  } = useBoolean();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm<DebtRequestType>();
  const isLoading = useSelector((state: RootState) => state.Storage.isLoading);
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const bankList = useSelector((state: RootState) => state.Partners.banks);
  const equityPartners = useSelector((state: RootState) => state.Partners.equityPartners);
  const fileSections = useSelector((state: RootState) => state.App.fileSections);
  const partnerId = useSelector((state: RootState) => state.Auth.user?.partnerId || '');
  const [documentList = [], seniorDebt, juniorDebt, otherDebt, bridgeDebt, costsBreakdown = []] = watch(['documentList', 'seniorDebt', 'juniorDebt', 'otherDebt', 'bridgeDebt', 'costsBreakdown']);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const editDrawer = useBoolean();
  const infoModal = useBoolean();
  const dispatch = useDispatch();
  const debouncedUpdateDebtRequest = debounce(updateDebtRequest, 500);
  const { rows } = useFinanceResult(detailsData?.project?.scenario?.calculate?.input, detailsData?.project?.scenario?.calculate?.output, false);
  const proverestUrl = useSelector((state: RootState) => state.App.config?.proverestUrl);
  const [currentDebt, setCurrentDebt] = useState<DebtName>('seniorDebt');

  const toggleOpenDrawer = useCallback(() => {
    setOpenDrawer(!openDrawer);
  }, [openDrawer]);

  const loadDocuments = useCallback(async () => {
    const documents = await getDocumentsList('debtRequestId', debtId, partnerId);
    setValue('documentList', documents);
  }, [debtId, partnerId, setValue]);

  const loadDebtDetails = useCallback(async () => {
    const details = await getDebtRequestDetails(debtId, dispatch, partnerId);
    if (details) {
      setValue('seniorDebt', details.seniorDebt);
      setValue('juniorDebt', details.juniorDebt);
      setValue('bridgeDebt', details.bridgeDebt);
      setValue('otherDebt', details.otherDebt);
      setValue('documentList', details.documentList);
      setValue('costsBreakdown', rows);
    }
  }, [debtId, dispatch, rows, setValue, partnerId]);

  useEffect(() => {
    if (detailsData.project?._id) {
      dispatch(getPartners('Bank', 'banks'));
      dispatch(getPartners('Equity Partner', 'equityPartners'));
    }
  }, [dispatch, detailsData.project?._id]);

  useEffect(() => {
    if (rows?.length) {
      loadDebtDetails();
    }
  }, [dispatch, loadDebtDetails, rows?.length]);

  const sendRequest = (sendAnyway: boolean = false) => async () => {
    infoModal.onToggle();
    if (isDocumentsListWaiting() && !sendAnyway) return;
    additionalSubmitFunction?.(documentList);
    const isCompleted = !isDocumentsListWaiting() || sendAnyway;
    await updateDebtRequest(debtId, { _id: id }, partnerId, isCompleted ? 'completed' : 'draft');
    if (sendAnyway) {
      closeClickHandler();
    }
  };

  const addPreliminaryDebtRequestToStore = (banks: BankType[] = [], type: DeptNameType, detailsList?: FinancialStepType) => {
    banks.forEach(bank => {
      dispatch(addPreliminaryDebtRequest({
        _id: bank._id,
        debtRequestId: bank._id,
        type: type,
        bankName: bank.companyName || '',
        bankSrc: bank.logoUrl,
        preliminaryDebtRequest: {
          ...detailsList,
          date: new Date().toISOString(),
        },
      }));
    });
  };

  const closeClickHandler = () => {
    if (detailsData?.project?._id) {
      dispatch(loadFinancialData(detailsData?.project?._id));
    }
    router.push('financial');
  };

  const onSubmit = () => {
    if (Boolean(Object.keys(errors).length)) return;
    sendRequest(false)();
  };

  const costsBreakdownConfig: RightBlockType<DebtRequestType, TableCustomProps>[] = useMemo(() => [
    {
      name: 'costsBreakdown',
      headLabels: TABLE_HEAD,
      bodyCells: <>
        {costsBreakdown?.map((row, index) => (
          <DebtRequestRow
            key={index}
            row={row}
          />
        ))}
      </>,
      noDataFound: false,
      correspondingComponent: TableCustom,
      tableMinWidth: 0,
    },
  ], [costsBreakdown]);

  const updateFilesFormValue = useCallback((files: FinanceUploadFileType[]) => {
    setValue('documentList', files);
    loadDocuments();
  }, [loadDocuments, setValue]);

  const documentListConfig: RightBlockType<DebtRequestType, RequestFileProps>[] = useMemo(() => [
    {
      name: 'documentList',
      title: uploadDocTitle,
      subTitle: uploadDocSubTitle,
      requestBtnTitle: 'Request Documents',
      correspondingComponent: RequestFile,
      debtRequestId: debtId,
      setFormValue: updateFilesFormValue,
      uploadedDocuments: uploadedDocuments,
      selectedDocumentTypes: documentList,
      documentTypes: fileSections.find(section => section.section === 'Financing')?.filenames || [],
      toggleOpenDrawer,
      showRequestAbility,
      openDrawer,
      badgeColorForUploaded,
      badgeTitleForNew,
      badgeColorForNew,
    },
  ], [badgeColorForNew, badgeColorForUploaded, badgeTitleForNew, documentList, fileSections, debtId, openDrawer, showRequestAbility, toggleOpenDrawer, updateFilesFormValue, uploadDocSubTitle, uploadDocTitle, uploadedDocuments]);

  const generateDebtField = useCallback((detailsList: FinancialStepType | undefined, file?: FinanceUploadFileType): FieldType[] => {
    const defaultFields: FieldType[] = [
      {
        label: 'Total Funds',
        value: showEmptyValue(fNumber(detailsList?.totalFunds)) + ` ${DEFAULT_CURRENCY}`,
      },
      {
        label: 'Rate',
        value: showEmptyValue(fPercent(detailsList?.rate)),
      },
      {
        label: 'Fee',
        value: showEmptyValue(fPercent(detailsList?.fee)),
      },
      {
        label: 'LTC',
        value: showEmptyValue(fPercent(detailsList?.ltc)),
      },
      {
        label: 'LTGDV',
        value: showEmptyValue(fPercent(detailsList?.ltgdv)),
      },
      {
        label: 'Profit share',
        value: showEmptyValue(fPercent(detailsList?.profitShare)),
      },
    ];
    if (file) {
      defaultFields.push({
        label: 'Term Sheet',
        value: file.filename,
        color: '#3698AF',
      });
    }
    return defaultFields;
  }, []);

  const openBankModalClickHandler = useCallback((debtName: DebtName) => () => {
    setCurrentDebt(debtName);
    onToggle();
  }, [onToggle]);

  const updateSelectedBanks = useCallback((selectedBanks: BankType[] = [], debtKey: DebtName, bankId: string) => (event: MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    if (target.tagName.toLowerCase() === 'a') {
      return;
    }
    const updatedBankList = selectedBanks.map(bank => {
      return bank._id === bankId ?
        {
          ...bank,
          selected: !bank.selected,
        } : bank;
    }).filter(bank => bank.selected);
    const id = getValues(`${debtKey}._id`) || '';
    updateDebtRequest(debtId, {
      _id: id,
      partnerIds: updatedBankList.map(bank => bank._id),
    }, partnerId);
    setValue(`${debtKey}.selectedBanks`, updatedBankList);
  }, [debtId, getValues, partnerId, setValue]);

  const selectAllHandler = (filteredBanks: BankType[], debtKey: DebtName) => () => {
    const updatedBankList = filteredBanks.map(bank => {
      return {
        ...bank,
        selected: true,
      };
    });
    const id = getValues(`${debtKey}._id`) || '';
    const banks = getValues(`${debtKey}.selectedBanks`) || [];
    const unique = _.uniqBy([...banks, ...updatedBankList], '_id');
    updateDebtRequest(debtId, {
      _id: id,
      partnerIds: unique.map(bank => bank._id),
    }, partnerId);
    setValue(`${debtKey}.selectedBanks`, unique);
  };

  const editClickHandler = useCallback((debtName: DebtName) => () => {
    setCurrentDebt(debtName);
    editDrawer.onToggle();
  }, [editDrawer]);

  const onPreviewClickHandler = useCallback((debtKey: DebtName) => () => {
    openInNewTab(`${paths.reports.bankReport(id, debtId)}?debtKey=${debtKey}`);
  }, [debtId, id]);

  const updateNoteFieldValue = useCallback((debtKey: DebtName) => (event: ChangeEvent<HTMLInputElement>) => {
    const id = getValues(`${debtKey}._id`) || '';
    const note = event.target.value;
    setValue(`${debtKey}.emailNotes`, note);
    debouncedUpdateDebtRequest(debtId, {
      _id: id,
      note,
    }, partnerId);
  }, [debouncedUpdateDebtRequest, partnerId, debtId, getValues, setValue]);

  const generateDebtListRightBlock = useCallback((debtName: DebtName, detailsListName: keyof DebtRequestType, notesName: keyof DebtRequestType, detailsList?: DebtDetailsType, selectedBanks?: BankType[]): RightBlockType<DebtRequestType, (TextFieldProps & DetailsListProps<StackProps>)>[] => {
    return [
      {
        required: true,
        name: debtName,
        label: 'Bank Or Financing Partner',
        value: null,
        onClick: openBankModalClickHandler(debtName),
        rules: {
          validate: value => {
            return value.selectedBanks?.length > 0;
          },
        },
        InputProps: {
          readOnly: true,
          startAdornment: (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
                py: '14px',
              }}
            >
              {selectedBanks?.map((selectedBank) => (
                <Chip
                  key={selectedBank._id}
                  label={selectedBank.companyName}
                  size='small'
                  color='default'
                  variant='soft'
                  sx={{ mr: 1 }}
                  onDelete={updateSelectedBanks(getValues(`${debtName}.selectedBanks`), debtName, selectedBank._id)}
                />
              ))}
            </Box>
          ),
        },
        correspondingComponent: TextField,
        sx: { '& .MuiOutlinedInput-input': { width: '0' } },
      },
      {
        name: detailsListName,
        fields: generateDebtField(detailsList),
        correspondingComponent: DetailsList,
        wrappedComponent: Stack,
        btnTitle: 'Edit',
        btnConfig: { onClick: editClickHandler(debtName) },
        wrappedProps: {
          sx: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            border: (theme) => `1px solid ${alpha(theme.palette.text.secondary, 0.2)}`,
            borderRadius: 1.5,
          },
        },
      },
      {
        label: 'Email note',
        name: notesName,
        multiline: true,
        rows: 4,
        onChange: updateNoteFieldValue(debtName),
        correspondingComponent: TextField,
      },
    ];
  }, [editClickHandler, generateDebtField, getValues, openBankModalClickHandler, updateNoteFieldValue, updateSelectedBanks]);

  const generateFinancingAgreementConfig = useCallback((debt: FinancialTableType) => {
    return [
      {
        name: 'seniorDebt',
        primaryText: debt.bankName,
        avatarSrc: debt.bankSrc,
        showAvatar: true,
        avatarSx: { borderRadius: 1.5 },
        avatarAlt: debt.bankName,
        secondaryChildren: (
          <>
            <Link
              underline='none'
              target='_blank'
              href={paths.bankPartners.products(debt.bankId || '')}
            >
              Profile
            </Link>
          </>
        ),
        correspondingComponent: ListItemCustom,
      },
      {
        name: 'seniorDebt',
        correspondingComponent: Typography,
        variant: 'subtitle2',
        sx: { mb: -1.5 },
        children: 'Term Sheet',
      },
      {
        name: 'seniorDebt',
        fields: generateDebtField(debt.offer, debt.termSheet?.file),
        correspondingComponent: DetailsList,
        wrappedComponent: Stack,
        wrappedProps: {
          sx: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            border: (theme: Theme) => `1px solid ${alpha(theme.palette.text.secondary, 0.2)}`,
            borderRadius: 1.5,
          },
        },
      },
      {
        name: 'seniorDebt',
        value: [debt.termSheet?.message ?? ''],
        disabled: true,
        label: 'Email note',
        multiline: true,
        rows: 4,
        correspondingComponent: TextField,
      },
    ];
  }, [generateDebtField]);

  const configItemData: Map<string, DebtMapItem> = useMemo(() => new Map([
    [
      'Senior debt',
      {
        label: 'Senior Debt',
        debtName: 'seniorDebt',
        detailsListName: 'seniorDebt.detailsList' as keyof DebtRequestType,
        notesName: 'seniorDebt.emailNotes' as keyof DebtRequestType,
        selectedBanks: seniorDebt?.selectedBanks,
      },
    ],
    [
      'Junior debt',
      {
        label: 'Junior Debt',
        debtName: 'juniorDebt',
        detailsListName: 'juniorDebt.detailsList' as keyof DebtRequestType,
        notesName: 'juniorDebt.emailNotes' as keyof DebtRequestType,
        selectedBanks: juniorDebt?.selectedBanks,
      },
    ],
    [
      'Other debt',
      {
        label: 'Other Debt',
        debtName: 'otherDebt',
        detailsListName: 'otherDebt.detailsList' as keyof DebtRequestType,
        notesName: 'otherDebt.emailNotes' as keyof DebtRequestType,
        selectedBanks: otherDebt?.selectedBanks,
      },
    ],
    [
      'Bridge debt',
      {
        label: 'Bridge Debt',
        debtName: 'bridgeDebt',
        detailsListName: 'bridgeDebt.detailsList' as keyof DebtRequestType,
        notesName: 'bridgeDebt.emailNotes' as keyof DebtRequestType,
        selectedBanks: bridgeDebt?.selectedBanks,
      },
    ],
  ]), [bridgeDebt?.selectedBanks, juniorDebt?.selectedBanks, otherDebt?.selectedBanks, seniorDebt?.selectedBanks]);

  const generateDebtListConfig = useCallback(() => {
    if (isEditEnable) {
      return costsBreakdown?.map(item => {
        const type = item.type || '';
        if (configItemData.get(type) === undefined) return;
        const debtName = configItemData.get(type)!.debtName;
        const detailsListName = configItemData.get(type)!.detailsListName;
        const notesName = configItemData.get(type)!.notesName;
        const selectedBanks = configItemData.get(type)!.selectedBanks;
        return {
          leftBlock: <LeftBlockComponent
            showPreview
            title={configItemData.get(type)?.label || UNSPECIFIED_VALUE}
            onPreviewClickHandler={onPreviewClickHandler(debtName)}
          />,
          rightBlock: generateDebtListRightBlock(debtName, detailsListName, notesName, getValues(`${debtName}.detailsList`), selectedBanks),
          layout: Card,
          stackConfig: defaultStackConfig,
        };
      });
    }
    return debts.map(debt => {
      return {
        leftBlock: <LeftBlockComponent
          title={`${debt.type} Debt Sign Loan`}
        />,
        rightBlock: generateFinancingAgreementConfig(debt),
        layout: Card,
        stackConfig: defaultStackConfig,
      };
    });
  }, [configItemData, costsBreakdown, debts, generateDebtListRightBlock, generateFinancingAgreementConfig, getValues, isEditEnable, onPreviewClickHandler]);

  const onCalculatorClickHandler = useCallback(() => {
    researchClickHelper(proverestUrl, '', detailsData.project?.scenario);
  }, [detailsData.project?.scenario, proverestUrl]);

  const debtRequestConfig: ConfigType<DebtRequestType, FieldsTypes, LayoutTypes>[] = useMemo(() => [
    {
      leftBlock: <LeftBlockComponent
        title='Finance Costs Breakdown'
        subTitle='You can edit the finance structure in the calculator'
        btnText='Calculator'
        btnClickHandler={onCalculatorClickHandler}
        btnProps={{ disabled: true }}
      />,
      rightBlock: costsBreakdownConfig as RightBlockType<DebtRequestType, FieldsTypes>[],
      layout: Card,
    },
    {
      leftBlock: <>
        {documentList?.length === 0 ? (
          <LeftBlockComponent
            title='Documents List'
          />
        ) : (
          <LeftBlockComponent
            title='Documents For Term Sheet'
            subTitle='Last request: today'
            extraTitle='Email: jane.smith@mail.com'
            btnText='Request More'
            btnVariant='outlined'
            btnClickHandler={toggleOpenDrawer}
          />
        )}
      </>,
      rightBlock: documentListConfig as RightBlockType<DebtRequestType, FieldsTypes>[],
      layout: Card,
    },
    ...generateDebtListConfig() as ConfigType<DebtRequestType, FieldsTypes, LayoutTypes>[],
  ], [costsBreakdownConfig, documentList?.length, documentListConfig, generateDebtListConfig, onCalculatorClickHandler, toggleOpenDrawer]);

  const isDocumentsListWaiting = useCallback(() => {
    return _.isEmpty(documentList) || _.some(documentList, { url: null });
  }, [documentList]);

  const toOverviewCLickHandler = useCallback(() => {
    router.push('overview');
  }, [router]);

  const partnersList = useMemo(() => {
    const filteredEquityPartners = equityPartners.filter(partner => partner.companySub1Type?.includes('Debt'));
    return [...bankList, ...filteredEquityPartners];
  }, [bankList, equityPartners]);

  return (
    <>
      <ProjectToolbar
        badgeTitle={detailsData.lead?.stage}
        title='Debt Request'
        crumbs={crumbs}
        badgeColor={allChipColors[detailsData.lead?.stage || 'In Progress']}
        badgeVariant='outlined'
      />
      <FormComponent
        onSubmitHandler={handleSubmit(onSubmit)}
        formConfig={debtRequestConfig}
        btnTitle='Send Request'
        btnConfig={{ variant: isDocumentsListWaiting() ? 'text' : 'contained' }}
        returnBtnTitle={isDocumentsListWaiting() ? 'To Overview' : ''}
        returnBtnConfig={{ onClick: toOverviewCLickHandler }}
        isLoadingSubmitBtn={isLoading}
        control={control}
      />
      <DebtModal
        open={value}
        onClose={onFalse}
        selectedBanks={getValues(`${currentDebt}.selectedBanks`)}
        debtKey={currentDebt}
        banks={partnersList}
        bankSelectHandler={updateSelectedBanks}
        selectAllHandler={selectAllHandler}
      />
      <EditDrawer
        editDrawer={editDrawer}
        currentDebt={currentDebt}
        debtId={debtId}
        sectionId={getValues(`${currentDebt}._id`)}
        debtDetailsData={getValues(`${currentDebt}.detailsList`)}
        updateForm={setValue}
      />
      <InfoModal
        title={isDocumentsListWaiting() ? 'No required documents!' : 'Request Sent Successfully!'}
        open={infoModal.value}
        icon={isDocumentsListWaiting() ? <DeclineBankIcon/> : <SuccessfulBankIcon/>}
        onClose={isDocumentsListWaiting() ? infoModal.onFalse : closeClickHandler}
        btnTitle={isDocumentsListWaiting() ? 'Cancel' : 'Close'}
        btnConfig={{
          onClick: isDocumentsListWaiting() ? infoModal.onFalse : closeClickHandler,
          variant: isDocumentsListWaiting() ? 'contained' : 'outlined',
        }}
        secondBtnTitle={isDocumentsListWaiting() ? 'Send Request' : ''}
        subTitle={isDocumentsListWaiting() ? 'Application may not be considered without the necessary documents.' : ''}
        secondBtnConfig={{ onClick: sendRequest(true) }}
      />
    </>
  );
};
