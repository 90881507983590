import { TabType } from 'src/components';

export const TABS: TabType[] = [
  {
    value: 'Offering',
    label: 'Offering',
  },
  {
    value: 'Overview',
    label: 'Overview',
  },
  {
    value: 'Reports',
    label: 'Reports',
  },
  {
    value: 'Team',
    label: 'Team',
  },
  {
    value: 'Files',
    label: 'Files',
  },
  {
    value: 'Assets',
    label: 'Assets',
  },
];

export const TABLE_HEAD = [
  {
    _id: '',
    label: 'Investor',
  },
  {
    _id: '',
    label: 'Project name',
    width: 150,
  },
  {
    _id: '',
    label: 'Reserved',
    width: 120,
  },
  {
    _id: '',
    label: 'Secured',
    width: 120,
  },
  {
    _id: '',
    label: 'Commission',
    width: 120,
  },
  {
    _id: '',
    label: 'Last Update',
    width: 160,
  },
  {
    _id: '',
    label: 'Status',
  },
  {
    _id: 'action',
    width: 96,
  },
];

export const MARKETPLACE_PROJECT_REPORTS_TABLE_HEAD = [
  {
    _id: 'name',
    label: 'Report Name',
  },
  {
    _id: 'createdAt',
    label: 'Date Created',
    width: 200,
  },
  {
    _id: 'updatedAt',
    label: 'Date Updated',
    width: 200,
  },
  {
    _id: 'published',
    label: 'Visibility',
    width: 200,
  },
  {
    _id: 'open',
    width: 140,
  },
];

export const MARKETPLACE_PROJECT_FILES_TABLE_HEAD = [
  {
    _id: 'name',
    label: 'Name / Size',
  },
  {
    _id: 'section',
    label: 'Section',
    width: 120,
  },
  {
    _id: 'uploadedAt',
    label: 'Uploaded',
    width: 120,
  },
  {
    _id: 'requestedAt',
    label: 'Requested',
    width: 120,
  },
  {
    _id: 'controls',
    width: 172,
  },
];
